import { useEffect, useState } from "react";


export default function useStaggeredValue(value, timeout) {
  const [_staggeredValue, _setStaggeredValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => _setStaggeredValue(value), timeout);

    return () => {
      clearTimeout(timer);
    }
  }, [value, timeout]);

  return _staggeredValue;
}