import { makeStyles } from "tss-react/mui";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress, Grid, Box, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { LocationOffOutlined as LocationOffIcon } from "@mui/icons-material";
import { useStation } from "../../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import { useTranslation } from "react-i18next";
import CountrySelect from "../CountrySelect";
import MapboxSearch from "../MapboxSearch";
import { Location } from "@emberly/zenith-client";
import { GeoCode } from "../../../../common/maphelpers";

const useStyles = makeStyles()(
  (theme) => ({
    pickerActions: {
      padding: theme.spacing(3)
    },
    mapContainer: {
      position: "relative",
      minHeight: "100%",
      height: "220px",
      maxHeight: "700px",
      width: "100%",
      overflow: "hidden",
      "& .map": {
        borderRadius: "8px",
        width: "100%",
        height: "100%",
      }
    },
    noCoordinatesContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      background: "rgba(255,255,255,0.6)",
      width: "100%",
      height: "100%",
      zIndex: 10,
      "& .MuiTypography-root": {
        maxWidth: "200px",
        display: "block"
      }
    }
  })
);

export default function AddressPickerDialog(props) {
  const { value, onConfirm, onCancel } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { station } = useStation();
  const mapRef = useRef(null);

  const [coordinates, setCoordinates] = useState(value.coordinates || null);
  const [address, setAddress] = useState(new Location({ address: value.address || "", mapboxId: value.mapboxId }));
  const [address2, setAddress2] = useState(value.address2 || "");
  const [country, setCountry] = useState(value.country || "");
  const [postCode, setPostCode] = useState(value.postCode || "");
  const [place, setPlace] = useState(value.place || "");
  const [marker, setMarker] = useState(null);

  const onMapMounted = useCallback((component) => {
    try {
      if (!component) return;

      component.innerHTML = "";
      const hasExistingCoords = !!(value?.coordinates || station?.location?.coordinates);

      const map = new mapboxgl.Map({
        container: component, // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: hasExistingCoords ? (value?.coordinates || station?.location?.coordinates) : [8.2, 60], // starting position [lng, lat]
        zoom: hasExistingCoords ? 12 : 5, // starting zoom
        attributionControl: false
      });

      mapRef.current = map;
      let marker = new mapboxgl.Marker();
      let markerAdded = false;

      map.on("load", () => {
        // Add features
        if (!!value?.coordinates) {
          marker.setLngLat(value.coordinates);

          if (!markerAdded) {
            markerAdded = true;
            marker.addTo(map);
          }
        }
      });

      setMarker(marker);

    } catch (err) {
      console.log(err);
    }
  }, [value, station]);

  const onPickerClose = useCallback(() => {
    mapRef.current?.remove();
    onCancel();
  }, [onCancel]);

  const onPickerConfirm = useCallback(() => {
    mapRef.current?.remove();
    
    onConfirm(new Location({ 
      address: address?.address || "", 
      mapboxId: address?.mapboxId || "",
      coordinates: coordinates,
      address2: address2,
      place: place, 
      country: country, 
      postCode: postCode
    }));
  }, [coordinates, onConfirm, address, coordinates, address2, place, country, postCode]);

  const onAddressChange = useCallback((loc) => {
    setAddress(loc);

    if (!!loc.mapboxId) {
      setPostCode(loc.postCode || "");
      setCountry(loc.country || "");
      setAddress2(loc.address2 || "");
      setPlace(loc.place || "");

      setCoordinates(loc.coordinates);

      marker?.setLngLat(loc.coordinates);
      marker?.addTo(mapRef.current);


      mapRef.current?.flyTo({
        center: loc.coordinates,
        zoom: 14.5,
        padding: 32
      });
    } else {
      marker.remove();
      setCoordinates(null);
    }

  }, [marker]);

  useEffect(() => {
    if (!!address?.address && !!country && !address.mapboxId) {

      const timer = setTimeout(async () => {
        const geoloc = await GeoCode(new Location({ address: address?.address, place: place, country: country, postCode: postCode }));

        if (!!geoloc) {
          setCoordinates(geoloc);
          
          marker?.setLngLat(geoloc);
          marker.addTo(mapRef.current);

          mapRef.current?.flyTo({
            center: geoloc,
            zoom: 14.5,
            padding: 32
          });

        } else if (!address.mapboxId) {
          marker.remove();
          setCoordinates(null);
        }

      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [postCode, address?.address, place, country, marker]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onPickerClose}
    >
      <DialogTitle>
        {t("common:location:addressPickerTitle")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} direction="row">

          <Grid item container xs={12} md={6} spacing={2}>

            <Grid item xs={12}>
              <MapboxSearch
                label={t("entity:location:address")}
                value={address}
                onChange={onAddressChange}
                freeSolo
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t("entity:location:address2")}
                variant="filled"
                value={address2}
                onChange={ev => setAddress2(ev.target.value)}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label={t("entity:location:postCode")}
                variant="filled"
                value={postCode}
                onChange={ev => setPostCode(ev.target.value)}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label={t("entity:location:place")}
                variant="filled"
                value={place}
                onChange={ev => setPlace(ev.target.value)}
                fullWidth
                size="small"
              />
            </Grid>


            <Grid item xs={12}>
              <CountrySelect
                onChange={v => setCountry(v)}
                value={country}
                label={t("entity:location:country")}
              />
            </Grid>

          </Grid>

          <Grid item xs={12} md={6}>

            <div className={classes.mapContainer}>
              <div ref={onMapMounted} className="map"></div>
              {
                !coordinates ? (
                  <Grid container className={classes.noCoordinatesContainer} justifyContent="center" alignItems="center" direction="column">
                    <Grid item>
                      <LocationOffIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" textAlign="center">{t("common:location:pickerNoCoordinates")}</Typography>
                    </Grid>
                  </Grid>
                ) : null
              }
            </div>

          </Grid>

        </Grid>

      </DialogContent>

      <DialogActions className={classes.pickerActions}>
        <Button onClick={onPickerClose} size="large">{t("common:cancel")}</Button>
        <Button onClick={onPickerConfirm} variant="contained" color="primary" size="large">{t("common:confirm")}</Button>
      </DialogActions>

    </Dialog>
  );
}


