import { useCallback, useMemo } from "react";
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import SurfaceSection from "../../../../common/core/SurfaceSection";
import { Defaults, useEntities, useEntityField } from "@emberly/zenith-client";
import { MissionEnumsLists } from "../../../../../common/constants";
import { makeStyles } from "tss-react/mui";
import { CheckBoxOutlineBlank as CheckBoxBlankIcon, CheckBox as CheckBoxIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    chip: {
      borderRadius: theme.spacing(1)
    }
  })
);

export default function DetailsSection(props) {
  const { locked } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const type = useEntityField("details.type", Defaults.Enum);
  const cause = useEntityField("details.cause", Defaults.Null);
  const description = useEntityField("details.description", Defaults.String);
  const tags = useEntityField("details.tags", Defaults.List);

  const { entities: missionTags } = useEntities("MissionTag"); // pass missions down to table
  const { entities: causes } = useEntities("Cause");
  
  const causesSorted = useMemo(() => [...causes].sort((a,b) => a.title?.localeCompare(b.title)), [causes])

  const allTags = useMemo(() => {
    const dict = new Map();
    missionTags.forEach(t => dict.set(t.id, { title: t.title, id: t.id, checked: false }));
    tags.value.forEach(t => dict.set(t.id, { title: t.name, id: t.id, checked: true }));
    return [...dict.values()].sort((a, b) => a.title - b.title);
  }, [tags.value, missionTags]);

  const onToggleTag = useCallback((tag) => {
    tags.onChange(
      null,
      tag.checked ?
        tags.value.filter(t => t.id !== tag.id) :
        [...tags.value, { id: tag.id, name: tag.title }]
    );
  }, [tags.value, tags.onChange]);


  const onSelectCause = useCallback(ev =>
    cause.onChange(null, { id: ev.target.value, name: causes.find(t => t.id === ev.target.value).title }),
    [causes, cause.onChange]
  );

  const causeDoesntExist = !!cause.value?.id && !causes.find(t => t.id === cause.value.id);

  return (
    <SurfaceSection title={t("mission:cards:details:service")}>

      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:details:type")}</InputLabel>
          <Select onChange={type.onChange} value={type.value}>
            {MissionEnumsLists.Type.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:type:${type}`)}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="filled" disabled={locked}>
          <InputLabel>{t("mission:cards:details:cause")}</InputLabel>
          <Select
            onChange={onSelectCause}
            value={cause.value?.id || ""}
          >
            {causeDoesntExist ? (
              <MenuItem value={cause.value.id}>{cause.value.name}</MenuItem>
            ) : null}

            {causesSorted.map((c, key) => <MenuItem value={c.id} key={key}>{c.title}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label={t("mission:cards:details:description")}
          variant="filled"
          value={description.value}
          onChange={description.onChange}
          rows={5}
          fullWidth
          multiline
          disabled={locked}
        />
      </Grid>

      <Grid item container spacing={1} xs={12}>
        {allTags.map(
          (t, key) =>
            <Grid item key={key}>
              <Chip
                disabled={locked}
                className={classes.chip}
                label={t.title}
                color={t.checked ? "primary" : undefined}
                variant={t.checked ? "filled" : "outlined"}
                onClick={() => onToggleTag(t)}
                icon={t.checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}
              />
            </Grid>
        )}
      </Grid>

    </SurfaceSection>
  );
}
