import { useMemo, useCallback, useState } from "react";
import { MenuItem, Menu, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { useNavigate } from "react-router-dom";
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useEntities, useEntityFunctions, Compare, Task } from "@emberly/zenith-client";
import ConfirmDialog from "../../common/inputs/ConfirmDialog";
import ResponsivePage from "../../common/core/ResponsivePage";
import { MissionEnums, TaskEnums } from "../../../common/constants";
import { makeStyles } from "tss-react/mui";
import { useMoveVehicleToDriver } from "../../../providers/DriverProvider";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    noselect: {
      userSelect: "none"
    },
  })
);

const QUERY = {
  name: "active_driver_missions",
  and: [
    {
      path: "state",
      comparer: Compare.EQ,
      value: MissionEnums.State.Created,
    },
    {
      path: "salvageTasks.execution.history.completed",
      comparer: Compare.EQ,
      value: null,
    },
    {
      path: "salvageTasks.execution.driverAssignedState",
      comparer: Compare.EQ,
      value: TaskEnums.DriverAssignedState.Accepted,
    },
  ]
}

export default function DriverRegister() {

  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateEntity } = useEntityFunctions("Driver");
  const { entities: activeMissions } = useEntities("Mission", QUERY);

  const onRowClick = useCallback((params, ev) => {
    //navigate(`./${params.id}/details`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {

    const cm = new Map();

    activeMissions.forEach(m => {
      m?.salvageTasks.forEach((t) => {
        const uid = t.execution?.driver?.id;
        if (cm.has(uid)) {
          cm.set(uid, cm.get(uid) + 1);
        } else {
          cm.set(uid, 1);
        }
      });
    });

    return [
      {
        field: "name",
        headerName: t("driverRegister:fieldName"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "phoneNumber",
        headerName: t("driverRegister:fieldPhoneNumber"),
        valueGetter: params => params.row.phoneNumber || "",
        valueFormatter: params => params.value || "-",
        flex: 1,
        minWidth: 120,
        editable: true
      },
      {
        field: "active",
        headerName: t("driverRegister:fieldState"),
        flex: 1,
        minWidth: 120,
        editable: true,
        type: "boolean",
        valueGetter: params => params.row.active,
        renderEditCell: renderStateEditCell,
        renderCell: params => (
          <Chip className={classes.noselect} color={params.value ? "success" : undefined} label={t(params.value ? "driverRegister:fieldStateTrue" : "driverRegister:fieldStateFalse")} />
        )
      },
      {
        field: "activeMissions",
        headerName: t("driverRegister:fieldActiveMissions"),
        valueGetter: (params) => cm.has(params.id) ? cm.get(params.id) : 0,
        type: "number",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "vehicle",
        headerName: t("driverRegister:fieldVehicle"),
        flex: 1,
        minWidth: 120,
        editable: true,
        valueGetter: params => params.row.vehicle || null,
        valueFormatter: params => params.value?.name || "-",
        renderEditCell: renderVehicleEditCell,
        renderCell: params => (
          <span className={classes.noselect}>{params.value?.name || "-"}</span>
        )
      },
    ]
  }, [t, activeMissions, classes]);


  const onProcessRowUpdate = useCallback((newRow, oldRow) => {
    if (newRow.phoneNumber !== oldRow.phoneNumber) {
      updateEntity({ id: newRow.id, phoneNumber: newRow.phoneNumber }, ["phoneNumber"]);
    }
    return newRow;
  }, [updateEntity])

  return (
    <ResponsivePage
      title={t("driverRegister:title")}
      maxWidth={config.pageSize.large}
    >
      <EntityTable
        tableId="drivers"
        type="Driver"
        columns={columns}
        createTitle={t("driverRegister:create")}
        editTitle={t("driverRegister:edit")}
        emptyStateTitle={t("driverRegister:emptyStateTitle")}
        emptyStateDescription={t("driverRegister:emptyStateDescription")}
        onRowClick={onRowClick}
        processRowUpdate={onProcessRowUpdate}
        editable
        disableRowHover
      />
    </ResponsivePage>
  );
}

const EDIT_OFFSET = { marginLeft: "9px" };

const renderVehicleEditCell = (params) => {
  return <SelectVehicleInputCell {...params} />;
};

const ACTIVE_VEHICLES_QUERY = {
  path: "active",
  comparer: Compare.EQ,
  value: true,
  name: "active_vehicles_filter"
};

function SelectVehicleInputCell(props) {
  const { id, value: vehicle, field, row: driverEntity } = props;
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const { entities: vehicles } = useEntities("ServiceVehicle", ACTIVE_VEHICLES_QUERY);
  const [action, setAction] = useState(null);
  const moveVehicleToDriver = useMoveVehicleToDriver();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = async (value) => {
    const selectedVehicle = vehicles.find(t => t.id === value);

    // Show dialog to warn user that vehicle is already in user by another driver
    if (!!selectedVehicle && !!selectedVehicle.driver?.id && selectedVehicle.driver?.id !== id) {
      const task = new Task();
      setAction(task);

      try {
        await task.wait();
      } catch {
        return;
      } finally {
        setAction(null);
      }
    }

    if (vehicle?.id !== value) {
      await moveVehicleToDriver(driverEntity, selectedVehicle);
      const vehicleRef = { id: value, name: selectedVehicle?.title || "" };
      await apiRef.current.setEditCellValue({ id, field, value: vehicleRef });
    }

    apiRef.current.stopCellEditMode({ id, field });
  };


  return (
    <>
      <span ref={setAnchorEl} style={EDIT_OFFSET}>{vehicle?.name || "-"}</span>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => apiRef.current.stopCellEditMode({ id, field })}
      >

        <MenuItem value="" onClick={() => handleChange("")}>
          {t("driverRegister:noVehicle")}
        </MenuItem>

        {vehicles.map(
          (c, key) =>
            <MenuItem onClick={() => handleChange(c.id)} value={c.id} key={key} selected={vehicle?.id === c.id}>
              {c.title}
            </MenuItem>
        )}

      </Menu>
      <ConfirmDialog
        title={t("driverRegister:vehicleInUseTitle")}
        description={t("driverRegister:vehicleInUseDescription")}
        task={action}
      />
    </>
  );
}


const renderStateEditCell = (params) => {
  return <StateSelectInputCell {...params} />;
};

function StateSelectInputCell(props) {
  const { id, value: driverActive, field } = props;
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const { updateEntity } = useEntityFunctions("Driver");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = async (value) => {
    if (driverActive !== value) {
      updateEntity({ id, active: value }, ["active"]);
    }
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <>
      <Chip ref={setAnchorEl} color={driverActive ? "success" : undefined} label={t(driverActive ? "driverRegister:fieldStateTrue" : "driverRegister:fieldStateFalse")} />

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => apiRef.current.stopCellEditMode({ id, field })}
      >

        <MenuItem value={true} onClick={() => handleChange(true)}>
          {t("driverRegister:fieldStateTrue")}
        </MenuItem>

        <MenuItem value={false} onClick={() => handleChange(false)}>
          {t("driverRegister:fieldStateFalse")}
        </MenuItem>

      </Menu>
    </>
  );
}