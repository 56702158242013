import { makeStyles } from "tss-react/mui";
import { Grid, Typography, } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";
import FileImportSection from "./common/FileImportSection";
import { useCallback, useMemo } from "react";
import { useEntities } from "@emberly/zenith-client";
import { getCurrencyShorthand, useStation } from "../../../providers/StationProvider";
import { FixNumber, sanitizeNumber } from "../../../common/orders";

const useStyles = makeStyles()(
  (theme) => ({
    text: {
      "& a": {
        color: "inherit"
      },
    },
  })
);

const T_CONF = {
  TemplateLink: <a target="_blank" rel="noreferrer noopener" href="https://vg.no">link</a>,
  HelpLink: <a target="_blank" rel="noreferrer noopener" href="https://vg.no">link</a>,
};

export default function ProductImportPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entities: productGroups } = useEntities("ProductGroup");

  const { priceUnit } = useStation();

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: t("entity:product:code"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "name",
        headerName: t("entity:product:name"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "price.value",
        name: "price",
        headerName: t("entity:product:price"),
        flex: 1,
        minWidth: 100,
        type: "number",
        valueGetter: (params) => Number(params.row.price.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.price?.value)} ${getCurrencyShorthand(t, params.row.price?.currency)}`,
      },
      {
        field: "productGroup",
        headerName: t("entity:product:productGroup"),
        valueGetter: (params) => params.row.productGroup?.name || "-",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: t("entity:product:description"),
        flex: 1,
        minWidth: 100,
      },
    ]
  }, [t]);

  const onMapRow = useCallback(async (row, key) => {

    const productGroup = productGroups.find(pg => pg.code === row.productGroup);

    if (!productGroup) {
      return `${t("importPage:errors:productGroupNotFound")}: "${row.productGroup || ""}"`;
    }

    return {
      ...row,
      productGroup: { id: productGroup.id, name: `${productGroup.code}. ${productGroup.name}` },
      price: { value: sanitizeNumber(row.price), currency: priceUnit }
    };

  }, [productGroups, t, priceUnit]);

  return (
    <ResponsivePage
      maxWidth={config.pageSize.compact}
      title={t("importPage:products:header")}
      backButton
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary" className={classes.text}>
          <Trans
            i18nKey="importPage:products:description"
            components={T_CONF}
          />
        </Typography>
      </Grid>

      <FileImportSection
        name="product_import"
        type="Product"
        columns={columns}
        onMapRow={onMapRow}
        entityName={t("importPage:products:entityName")}
      />

    </ResponsivePage>
  );
}

