import { Button, Grid, LinearProgress, Typography, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ToggleSurface } from "../../../common/core/Surface";
import { parse } from "csv-parse/browser/esm";
import DragAndDropSection from "./DragAndDropSection";
import { useCallback, useState } from "react";
import SimpleTable from "../../../common/tables/SimpleTable";
import { useNotification } from "../../../../providers/NotificationProvider";
import { makeId, StatusCode, useMutateState } from "@emberly/zenith-client";
import { Merge as MergeIcon } from "@mui/icons-material";

const doNothing = () => { };
const loadingRows = [];

export default function FileImportSection(props) {
  const { name, columns, onMapRow, type, entityName, onProcessEntities } = props;
  const { t } = useTranslation();

  const mutateState = useMutateState();
  const { alert } = useNotification();
  const [hasData, setHasData] = useState(false);
  const [rows, setRows] = useState([]);
  const [working, setWorking] = useState(false);

  const onCancel = useCallback(() => {
    setRows([]);
    setHasData(false);
  }, []);

  const onImport = useCallback(async () => {
    try {
      setWorking(true);

      const response = await mutateState([{
        type,
        create: rows
      }]);

      const success = response?.statusCode === StatusCode.Success;

      if (success) {
        // import success
        await alert(
          t("importPage:common:successTitle"),
          t("importPage:common:successDescription"),
          "success"
        );

        setRows([]);
        setHasData(false);

      } else {
        // import error
        await alert(
          t("importPage:errors:importErrorTitle"),
          t("importPage:errors:importErrorDescription"),
          "error"
        );
      }

    } catch (err) {
      console.log(err);
      await alert(
        t("importPage:errors:importErrorTitle"),
        t("importPage:errors:importErrorDescription"),
        "error"
      );
    } finally {
      setWorking(false);
    }
  }, [rows, type, alert, type]);

  const onUpload = useCallback((rawCSVData) => {
    setHasData(true);
    setRows([]);
    setWorking(true);


    parse(
      rawCSVData,
      {
        bom: true,
        delimiter: ";",
        encoding: "utf8"
      }, async (err, records) => {

        if (err) {

          await alert(
            t("importPage:errors:alert"),
            err.toString(),
            "error"
          );
          setHasData(false);
          setWorking(false);
          return;

        } else {

          // Header validation
          const headerRow = records[0];
          let headerMapping = {};
          let headerMappingInv = [];

          for (let i = 0; i < headerRow.length; i++) {
            let h = headerRow[i];

            let column = columns.find(t => h === t.headerName);

            if (!column) {
              // Error
              await alert(
                t("importPage:errors:alert"),
                `${t("importPage:errors:invalidHeader")}: ${h}`,
                "error"
              );
              setHasData(false);
              setWorking(false);
              return;

            } else {
              headerMapping[column.name || column.field] = i;
              headerMappingInv.push(column.name || column.field);
            }
          }

          // Datatype specific validation
          let dataRows = [];

          for (let i = 1; i < records.length; i++) {
            let record = records[i];

            if (isRecordEmpty(record)) continue;

            let entityTpl = {
              id: makeId()
            };

            headerMappingInv.forEach((field, key) => {
              entityTpl[field] = record[key];
            });

            let row = await onMapRow(entityTpl, i - 1);

            if (typeof row === "string") {
              // Error

              await alert(
                `${t("importPage:errors:alert")}: ${t("importPage:errors:invalidRow")} "${i}"`,
                `${row}`,
                "error"
              );

              setHasData(false);
              setWorking(false);
              return;

            } else {
              dataRows.push(row);
            }
          }

          if (typeof onProcessEntities === "function") {
            await onProcessEntities(dataRows);
          }

          setRows(dataRows);
          setWorking(false);
        }
      });
  }, [onMapRow, alert, columns, onProcessEntities]);

  return (
    <>

      <ToggleSurface xs={12} collapse={hasData}>
        <Grid item container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h6">{t("importPage:common:selectFileTitle")}</Typography>
          </Grid>

          <DragAndDropSection onChange={onUpload} />

        </Grid>
      </ToggleSurface>


      <ToggleSurface xs={8} collapse={!rows.length}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("importPage:common:confirmTitle")} {rows.length} {entityName}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="bpdy1">{t("importPage:common:confirmDescription")}</Typography>
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"} spacing={2}>
            <Grid item>
              <Button onClick={onCancel} disabled={working}>{t("importPage:common:cancel")}</Button>
            </Grid>
            <Grid item>
              <Button onClick={onImport} startIcon={<MergeIcon />} variant="contained" color="primary" disabled={working}>{t("importPage:common:confirm")}</Button>
            </Grid>
          </Grid>
        </Grid>
      </ToggleSurface>

      <ToggleSurface collapse={!working}>
        <LinearProgress variant="indeterminate" color="primary" sx={{ width: "100%" }} />
      </ToggleSurface>

      <ToggleSurface xs={12} collapse={!rows.length || working}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("importPage:common:preview")}</Typography>
          </Grid>
          <SimpleTable
            loading={working}
            tableId={name}
            columns={columns}
            rows={working ? loadingRows : rows}
            emptyStateTitle={t("agreement:discountedProductsCard:emptyStateTitle")}
            emptyStateDescription={t("agreement:discountedProductsCard:emptyStateDescription")}
            onRowClick={doNothing}
          />
        </Grid>
      </ToggleSurface >

    </>
  );
}


function isRecordEmpty(record) {
  return !record.find(t => !!t);
}