import { Fragment, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useDriver } from "../../../providers/DriverProvider";
import VehicleSelector from "./common/VehicleSelector";
import DriverActiveStateSelector from "./common/DriverActiveStateSelector";
import { useDevice } from "../../../providers/DeviceProvider";
import DriverTabSelector from "./common/DriverTabSelector";
import MissionItem from "./common/MissionItem";
import { ToggleSurface } from "../../common/core/Surface";
import EmptyState from "../../common/core/EmptyState";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    headerWidgetSlim: {
      width: "100%",
      height: "auto",
      paddingTop: theme.spacing(1),
      background: theme.palette.background.default,
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
    headerWidgetWide: {
      maxWidth: "100%",
      width: "450px",
      padding: theme.spacing(2),
      background: theme.palette.background.default,
      borderRadius: theme.spacing(2)
    },
    selectorsSlim: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    selectorsWide: {},
    tabs: {
      width: "100%"
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    emptyState: {
      height: "50vh",
      padding: theme.spacing(3)
    },
  })
);

export default function MyMissionsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const onTabSelected = useCallback((ev, val) => setTabIndex(val), []);
  const { slim } = useDevice();
  const { activeMissions, completedMissions } = useDriver();

  return (
    <ResponsivePage
      title={t("myMissions:title")}
      maxWidth={config.pageSize.small}
      headerWidget={
        <Grid item xs={12}>
          <div className={slim ? classes.headerWidgetSlim : classes.headerWidgetWide}>
            <Grid container>

              <Grid item container xs={12} spacing={1} className={slim ? classes.selectorsSlim : classes.selectorsWide}>
                <Grid item xs={6}>
                  <VehicleSelector />
                </Grid>

                <Grid item xs={6}>
                  <DriverActiveStateSelector />
                </Grid>
              </Grid>

              {
                slim ? (
                  <Grid item xs={12}>
                    <DriverTabSelector onChange={onTabSelected} value={tabIndex} />
                  </Grid>
                ) : null
              }

            </Grid>
          </div>
        </Grid>
      }
    >

      {!slim || tabIndex === 0 ? (
        <ToggleSurface title={slim ? undefined : t("myMissions:activeMissionsTab")}>
          <Grid item xs={12} container>

            {activeMissions.length === 0 ? (
              <EmptyState
                title={t("myMissions:emptyStateTitle")}
                description={t("myMissions:emptyStateDescription")}
              />
            ) : activeMissions.map((t, i) => (
              <Fragment key={i}>
                <MissionItem slim={slim} missionTask={t} />
                {i !== activeMissions.length - 1 || slim ? (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                ) : null}
              </Fragment>
            ))}

          </Grid>
        </ToggleSurface>
      ) : null}

      {(!slim && completedMissions.length !== 0) || tabIndex === 1 ? (
        <ToggleSurface title={slim ? undefined : t("myMissions:completedMissionsTab")}>
          <Grid item xs={12} container>

            {completedMissions.length === 0 ? (
              <EmptyState
                title={t("myMissions:completedEmptyStateTitle")}
                description={t("myMissions:completedEmptyStateDescription")}
              />
            ) : completedMissions.map((t, i) => (
              <Fragment key={i}>
                <MissionItem slim={slim} missionTask={t} />
                {i !== completedMissions.length - 1 || slim ? (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                ) : null}
              </Fragment>
            ))}

          </Grid>
        </ToggleSurface>
      ) : null}

    </ResponsivePage>
  );
}
