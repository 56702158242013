import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

export function useScrollState(active = true, topOffset = 0) {
  const [scrollTop, setScrollTop] = useState(true);
  const [scrollBottom, setScrollBottom] = useState(true);
  const location = useLocation();
  const scrollRef = useRef(null);

  useEffect(() => {
    if (active && !!scrollRef.current) {
      const scrollBar = scrollRef.current;

      let top = scrollBar.scrollTop === 0;
      let bottom = scrollBar.scrollTop + scrollBar.clientHeight === scrollBar.scrollHeight;

      setScrollTop(top);
      setScrollBottom(bottom);

      
      const onScroll = () => {
        
        const atTop = scrollBar.scrollTop <= topOffset;
        const atBottom = Math.ceil(scrollBar.scrollTop + scrollBar.clientHeight) >= scrollBar.scrollHeight;
        
        if (atTop !== top) {
          top = atTop;
          setScrollTop(top);
        }

        if (atBottom !== bottom) {
          bottom = atBottom;
          setScrollBottom(bottom);
        }
      };

      const timer = setTimeout(onScroll, 250);
      scrollBar.addEventListener("scroll", onScroll, { passive: true });

      return () => {
        clearTimeout(timer);
        scrollBar.removeEventListener("scroll", onScroll, { passive: true });
      };
    }
  }, [scrollRef, active, topOffset]);

  const dispatchEvent = useCallback(() => {
    setTimeout(() => scrollRef.current?.dispatchEvent(new CustomEvent("scroll")), 270);
  }, [scrollRef]);

  useEffect(() => {
    setTimeout(() => scrollRef.current?.dispatchEvent(new CustomEvent("scroll")), 270);
  }, [location.pathname, scrollRef]);

  return { scrollTop, scrollBottom, scrollRef, dispatchEvent };
}