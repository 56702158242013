import { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid, Typography, LinearProgress, Skeleton, } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../../common/core/ResponsivePage";
import { ToggleSurface } from "../../../common/core/Surface";
import { Info as InfoIcon, CreditCard as CreditCardIcon, Money as MoneyIcon, BookOnline as BookOnlineIcon } from "@mui/icons-material";
import { NavLink, useParams } from "react-router-dom";
import { CalcToPaySum, FixNumber } from "../../../../common/orders";
import { OrderEnums } from "../../../../common/constants";
import { useUnsettledOrders } from "../common/useUnsettledOrders";
import { useEntityById, useAuth } from "@emberly/zenith-client";
import { useStationCurrency } from "../../../../providers/StationProvider";
import { getMissionTargetDescription, getMissionJobDescription } from "../../../../providers/MissionProvider";
import { TimeView } from "../../../common/core/Time";
import config from "../../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    orderItem: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      }
    }
  })
);

export default function CashSettlementOrdersPage() {
  const { t } = useTranslation();
  const { settlementId } = useParams();
  const { orders, relatedOrders, loading } = useUnsettledOrders(settlementId); // handle loading state
  const { user } = useAuth();

  const showLoading = orders.length === 0 && loading;

  return (
    <ResponsivePage
      title={t("cashSettlementOrders:title")}
      maxWidth={config.pageSize.tiny}
      backButton
    >

      <ToggleSurface>
        {loading ? <LinearProgress variant="indeterminate" style={{ width: "100%" }} /> : null}

        <Grid item xs={12} container spacing={2}>
          {!showLoading ? (
            orders.map((order, index) => <OrderItem order={order} relatedOrders={relatedOrders} key={index} user={user} />)
          ) : null}
        </Grid>
      </ToggleSurface>

    </ResponsivePage>
  );
}

function OrderItem(props) {
  const { order, relatedOrders } = props;
  const { entity: mission } = useEntityById("Mission", order.missionId);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const sumToPay = useMemo(() => CalcToPaySum(order, relatedOrders), [order, relatedOrders]);
  const currency = useStationCurrency();
  const pm = order.payment.method;
  const showLoading = !mission;

  return (
    <Grid item xs={12} className={classes.orderItem}>
      <NavLink to={`./${order.missionId}/${order.id}`}>
        <Grid container spacing={1} alignItems="baseline">

          <Grid item xs>
            <Typography variant="body1">{getMissionJobDescription(t, mission)} #{order.missionNumber}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary">{showLoading ? <Skeleton width="86px" /> : <TimeView time={mission.created} /> }</Typography>
          </Grid>

          <Grid item xs={12} container alignContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <InfoIcon fontSize="small" />
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {!mission ? (
                  <Skeleton height={48} />
                ) : (
                  <>
                    {getMissionTargetDescription(mission)}<br />
                    {mission?.details?.cause?.name || ""}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container alignContent="center" spacing={1}>
            <Grid item>
              {pm === OrderEnums.PaymentMethod.Card ? <CreditCardIcon fontSize="small" /> : pm === OrderEnums.PaymentMethod.Cash ? <MoneyIcon fontSize="small" /> : <BookOnlineIcon fontSize="small" />}
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {FixNumber(sumToPay)} {currency} {pm === OrderEnums.PaymentMethod.Cash ? t("cashSettlementOrders:paymentMethodCash") : pm === OrderEnums.PaymentMethod.Card ? t("cashSettlementOrders:paymentMethodCard") : t("cashSettlementOrders:paymentMethodMobilePayment")} ({t("cashSettlementOrders:orderNumber")} {order.number})
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

        </Grid>
      </NavLink >
    </Grid >
  );
}
