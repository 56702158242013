import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";

export default function MissionTagRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "title",
        headerName: t("missionTags:fieldTitle"),
        flex: 1
      }
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("missionTags:fieldTitle"), path: "title", type: EntityFieldTypes.String, required: true },
  ], [t])

  return (
    <ResponsivePage
      title={t("missionTags:title")}
      maxWidth={config.pageSize.small}
    >
      <EntityTable
        tableId="mission_tags"
        type="MissionTag"
        columns={columns}
        fields={fields}
        createTitle={t("missionTags:create")}
        editTitle={t("missionTags:edit")}
        emptyStateTitle={t("missionTags:emptyStateTitle")}
        emptyStateDescription={t("missionTags:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}