import { makeStyles } from "tss-react/mui";
import { Divider, Grid, Button, List, ListItemText, ListItemButton, ListItemIcon, Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { ToggleSurface } from "../../common/core/Surface";
import SettlementSummaryCard from "./common/SettlementSummaryCard";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useUnsettledOrders } from "./common/useUnsettledOrders";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function MyCashSettlementPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { orders, relatedOrders, loading, settleOrders, recentlySettled, canSettle } = useUnsettledOrders();

  return (
    <ResponsivePage
      title={t("myCashSettlement:title")}
      maxWidth={config.pageSize.tiny}
    >

      <ToggleSurface>
        <Grid item xs={12} container spacing={2}>

          <Grid item xs={12}>
            {recentlySettled ? (
              <Alert severity="success">
                <AlertTitle>{t("myCashSettlement:settleSuccessTitle")}</AlertTitle>
                {t("myCashSettlement:settleSuccessDescription")}<br/>
              </Alert>
            ) : (
              <SettlementSummaryCard orders={orders} relatedOrders={relatedOrders} loading={loading} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" fullWidth size="large" disabled={!canSettle} onClick={settleOrders}>{t("myCashSettlement:settle")}</Button>
          </Grid>

          <Grid item xs={12}>
            <List className={classes.list}>
              <SectionItem
                to="./orders"
                primary={t("myCashSettlement:orders")}
                disabled={!canSettle}
                secondary={orders.length === 1 ? t("myCashSettlement:ordersDescriptionSingular") : `${orders.length} ${t("myCashSettlement:ordersDescription")}`}
              />
              <SectionItem
                to="./archive"
                primary={t("myCashSettlement:archive")}
                secondary={t("myCashSettlement:archiveDescription")}
              />
            </List>
          </Grid>

        </Grid>
      </ToggleSurface>

    </ResponsivePage>
  );
}


function SectionItem(props) {
  const { to, primary, secondary, disabled } = props;

  return (
    <NavLink to={disabled ? undefined : to}>
      <ListItemButton disabled={disabled}>
        <ListItemText
          primary={primary}
          secondary={secondary}
        />
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItemButton>
      <Divider />
    </NavLink>
  );
}

