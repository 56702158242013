import { Grid, Select, MenuItem, IconButton, Button, FormControl, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import HorizontalSelector from "../../../../common/inputs/HorizontalSelector";
import { useCallback, useState } from "react";
import LocationPicker from "../../../../common/inputs/LocationPicker";
import { Add as AddIcon } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { CancelOutlined as CancelIcon } from "@mui/icons-material";
import { useEntityField, Defaults, Task } from "@emberly/zenith-client";
import { MissionEnumsLists } from "../../../../../common/constants";
import CollapseGridItem from "../../../../common/core/CollapseGridItem";
import DateAndTimePicker from "../../../../common/inputs/DateAndTimePicker";
import DriverDetails from "./common/DriverDetails";
import StateCard from "./common/StateCard";
import { useMission } from "../../../../../providers/MissionProvider";
import ConfirmDialog from "../../../../common/inputs/ConfirmDialog";

const onDragStart = () => {
  if (window.navigator.vibrate) {
    window.navigator.vibrate(100);
  }
}

export default function SalvageTaskDetails(props) {
  const { path, route, reorder, onChangeRoute, onDeleted, canDelete } = props;

  const { t } = useTranslation();

  const [deleteAction, setDeleteAction] = useState(null);
  const taskId = useEntityField(`${path}.taskId`, Defaults.String);
  const resolvedAtLocation = useEntityField(`${path}.resolvedAtLocation`, Defaults.Boolean);
  const locationState = useEntityField(`${path}.locationState`, Defaults.Enum);
  const description = useEntityField(`${path}.description`, Defaults.String);
  const comment = useEntityField(`${path}.comment`, Defaults.String);
  const keyPlacement = useEntityField(`${path}.keyPlacement`, Defaults.String);
  const estimatedArrival = useEntityField(`${path}.execution.estimatedArrival`, Defaults.String);
  const { spliceList, locked } = useMission();

  const onResolvedAtLocation = useCallback((idx) => {
    const resolved = idx === 1;
    resolvedAtLocation.onChange(null, resolved);
    if (resolved) {
      onChangeRoute(null, route.locations.filter((_, i) => i === 0).map(t => t.getData()));
    }
  }, [resolvedAtLocation.onChange, onChangeRoute, route, route?.locations]);


  const onDeleteService = useCallback(async () => {
    try {
      const task = new Task();
      setDeleteAction(task);

      await task.wait();

      onDeleted(taskId.value);
      const index = Number(path.substring(path.lastIndexOf('.') + 1));
      await spliceList("salvageTasks", index);

    } catch (err) {
      console.log(err);
    } finally {
      setDeleteAction(null);
    }

  }, [spliceList, onDeleted, path, taskId.value]);

  // this one does not use the section object like others do, because of ordering issues with comment and description.
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={reorder}>
      <Droppable droppableId="locations">
        {(droppableProvided) => (

          <Grid ref={droppableProvided.innerRef} item container spacing={1} justifyContent="flex-start" alignItems="flex-start" direction="row">

            <Grid item xs={12}>
              <HorizontalSelector items={["Transport", "Fikses på plass"]} value={resolvedAtLocation.value ? 1 : 0} onChange={onResolvedAtLocation} disabled={locked} />
            </Grid>

            {route?.locations.map((location, idx) => (
              <Draggable draggableId={location.id + idx} index={idx} key={location.id + idx}>
                {(provided) => (
                  <Grid
                    container item xs={12}
                    ref={provided.innerRef}
                    className="draggable-location"
                    {...provided.draggableProps}
                  >
                    <LocationPicker
                      disabled={locked}
                      picker
                      draggable
                      label={t(idx === 0 ? "mission:cards:tasks:salvageTask:missionLocation" : idx === route.locations.length - 1 ? "mission:cards:tasks:salvageTask:transportTo" : "mission:cards:tasks:salvageTask:waypoint")}
                      value={location}
                      onChange={location.replaceFn}
                      dragHandleProps={provided.dragHandleProps}
                      enableContactSearching
                      enableWarehouseSearching
                    />
                    {
                      idx !== 0 ? (
                        <IconButton onClick={location.removeFn} disabled={locked}>
                          <CancelIcon />
                        </IconButton>
                      ) : null
                    }
                  </Grid>
                )}
              </Draggable>
            ))}

            {droppableProvided.placeholder}

            <Grid item xs={12}>
              <Button color="info" size="medium" onClick={route?.addEmptyFn} startIcon={<AddIcon />} disabled={resolvedAtLocation.value || locked}>
                {t(route?.locations.length === 1 ? "mission:cards:tasks:salvageTask:addTransportButton" : "mission:cards:tasks:salvageTask:addWaypointButton")}
              </Button>
            </Grid>

            <Grid item container lg={12} xl={6} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  disabled={locked}
                  label={t("mission:cards:tasks:salvageTask:description")}
                  variant="filled"
                  multiline
                  fullWidth
                  size="small"
                  value={description.value}
                  onChange={description.onChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="filled" disabled={locked}>
                  <InputLabel>{t("mission:cards:tasks:salvageTask:customerWaiting")}</InputLabel>
                  <Select onChange={locationState.onChange} value={locationState.value === 0 ? "" : locationState.value}>
                    {MissionEnumsLists.LocationState.map((type, value) => <MenuItem value={value} key={value}>{t(`mission:enums:locationState:${type}`)}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <CollapseGridItem item xs={12} in={locationState.value === 1}>
                <TextField
                  disabled={locked}
                  label={t("mission:cards:tasks:salvageTask:keyPlacement")}
                  variant="filled"
                  fullWidth
                  size="small"
                  value={keyPlacement.value}
                  onChange={keyPlacement.onChange}
                />
              </CollapseGridItem>

              <Grid item xs={12}>
                <TextField
                  disabled={locked}
                  label={t("mission:cards:tasks:salvageTask:comment")}
                  variant="filled"
                  multiline
                  fullWidth
                  size="small"
                  value={comment.value}
                  onChange={comment.onChange}
                />
              </Grid>

              <Grid item xs={12}>
                <DateAndTimePicker
                  disabled={locked}
                  label={t("mission:cards:tasks:salvageTask:eta")}
                  value={estimatedArrival.value}
                  onChange={estimatedArrival.onChange}
                  includeTime
                  fromNow
                />
              </Grid>
            </Grid>

            <Grid item container lg={12} xl={6} spacing={1}>
              <DriverDetails path={path} taskId={taskId.value} />
              <StateCard path={path} disabled={locked} />
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item>
                <Button color="neutral" variant="contained" onClick={onDeleteService} disabled={!canDelete || locked}>{t("mission:cards:tasks:salvageTask:onDeleteTitle")}</Button>
              </Grid>
            </Grid>

            <ConfirmDialog
              title={t("mission:cards:tasks:salvageTask:onDeleteTitle")}
              description={t("mission:cards:tasks:salvageTask:onDeleteDescription")}
              confirmText={t("mission:cards:tasks:salvageTask:onDeleteConfirm")}
              task={deleteAction}
            />
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}
