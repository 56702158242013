import { Chip } from "@mui/material";
import { useMemo } from "react";
import { GetTaskStateBackgroundColor, GetTaskStateTextColor } from "../../../common/mission";


export default function TaskStateChip(props) {
  const { className, label, size, state, driverAssignedState } = props;

  return (
    <Chip
      size={size}
      className={className}
      label={label}
      style={useMemo(() => ({
        color: GetTaskStateTextColor(state, driverAssignedState),
        backgroundColor: GetTaskStateBackgroundColor(state, driverAssignedState)
      }), [state, driverAssignedState])}
    />
  )
}