import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { useMission } from "../../../../providers/MissionProvider";
import FilesSection from "../../../common/sections/FilesSection";
import { ToggleSurface } from "../../../common/core/Surface";
import { Add as AddIcon } from "@mui/icons-material";
import config from "../../../../config";

export default function MissionTaskFilesPage() {
  const { t } = useTranslation();
  const { onSelectFiles, locked } = useMission();

  return (
    <ResponsiveSubPage
      title={t("taskFilesPage:title")}
      maxWidth={config.pageSize.small}
      backPath="../"
      backButton
      secondaryActionComponent="label"
      secondaryActionIcon={<AddIcon />}
      secondaryAction={
        <>
          {t("taskFilesPage:upload")}
          <input
            type="file"
            hidden
            accept="*"
            onChange={onSelectFiles}
            disabled={locked}
            multiple
          />
        </>
      }
    >
      <ToggleSurface xs={12}>
        <FilesSection />
      </ToggleSurface>
    </ResponsiveSubPage>
  );
}
