import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import ResponsivePage from "../../common/core/ResponsivePage";
import { EntityFieldTypes, useEntities } from "@emberly/zenith-client";
import config from "../../../config";

export default function ProductGroupRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: t("productGroup:fieldCode"),
        flex: 0.5,
        minWidth: 60,
      },
      {
        field: "name",
        headerName: t("productGroup:fieldName"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "account",
        headerName: t("productGroup:fieldAccount"),
        flex: 0.3,
        minWidth: 100,
      },
      {
        field: "vatCode",
        headerName: t("productGroup:fieldVatCode"),
        valueGetter: (params) => params.row.vatCode?.name || "-",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: t("productGroup:fieldDescription"),
        flex: 1,
        minWidth: 100,
      },
    ]
  }, [t]);

  const { entities: vatCodes } = useEntities("VatCode");

  const fields = useMemo(() => [
    { name: t("productGroup:fieldCode"), path: "code", type: EntityFieldTypes.String, required: true },
    { name: t("productGroup:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("productGroup:fieldAccount"), path: "account", type: EntityFieldTypes.String, required: true },
    { name: t("productGroup:fieldVatCode"), path: "vatCode", type: EntityFieldTypes.Select, options: vatCodes.map(t => ({ id: t.id, name: t.name })), required: true },
    { name: t("productGroup:fieldDescription"), path: "description", type: EntityFieldTypes.String },
  ], [t, vatCodes]);

  return (
    <ResponsivePage
      title={t("productGroup:title")}
      maxWidth={config.pageSize.large}
    >
      <EntityTable
        tableId="product_groups"
        type="ProductGroup"
        columns={columns}
        fields={fields}
        createTitle={t("productGroup:create")}
        editTitle={t("productGroup:edit")}
        emptyStateTitle={t("productGroup:emptyStateTitle")}
        emptyStateDescription={t("productGroup:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}