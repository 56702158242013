import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useEntities } from "@emberly/zenith-client";
import { ACTIVE_MISSIONS } from "../../../common/queries";
import { useDevice } from "../../../providers/DeviceProvider";

import HeatmapCard from "./cards/HeatmapCard";
import { useLocationStatistics } from "../../../hooks/useLocationStatistics";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      background: "rgb(240, 242, 245)"
    },
   
  })
);

export default function StatisticsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { slim } = useDevice();
  const { entities: missions } = useEntities("Mission", ACTIVE_MISSIONS);

  const { data, loading } = useLocationStatistics();

  return (
    <ResponsivePage
      maxWidth={config.pageSize.huge}
      title={t("statistics:title")}
      className={classes.root}
    >

      <HeatmapCard missions={missions} data={data} />

    </ResponsivePage>
  );
}
