import { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Table, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { ToggleSurface } from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useEntity, EntityFieldTypes } from "@emberly/zenith-client";
import EntityEditDialog from "../../../common/inputs/EntityEditDialog";
import { Add as AddIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    fieldTitle: {
      fontWeight: "600",
    },
    table: {
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        verticalAlign: "top"
      }
    }
  })
);

export default function ContactInformationCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity, updateEntity, updateEntityField } = useEntity();
  const [dialog, setDialog] = useState(false);

  const fields = useMemo(() => [
    { name: t("contactRegister:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("contactRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Address },
    { name: t("contactRegister:fieldEmail"), path: "email", type: EntityFieldTypes.String, dataType: "email" },
    { name: t("contactRegister:fieldPhoneNumber"), path: "phoneNumber", type: EntityFieldTypes.String },
    { name: t("contactRegister:fieldOrganizationNumber"), path: "organizationNumber", type: EntityFieldTypes.String },
    { name: t("contactRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
    { name: t("contactRegister:fieldStandardAgreement"), path: "standardAgreement", type: EntityFieldTypes.Search, entityType: "Agreement", searchPath: "name", valueGetter: v => ({ id: v.id, name: v.name }) },
  ], [t]);

  const onEditCancel = useCallback(() => {
    setDialog(false);
  }, []);

  const onEditConfirm = useCallback((values, updatedFields) => {
    setDialog(false);
    updateEntity(values, updatedFields);
  }, [updateEntity]);

  const addCustomerInformation = useCallback(() => {
    updateEntityField("isCustomer", true);
  }, [updateEntityField]);

  return (
    <ToggleSurface title={t("contact:contactInformationCard:title")} md={12} lg={6}>
      <Grid item container spacing={1}>

        <Grid item>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:address")}</TableCell>
                <TableCell>{entity?.location?.address || "-"}</TableCell>
              </TableRow>


              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:email")}</TableCell>
                <TableCell>{entity?.email || "-"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:phoneNumber")}</TableCell>
                <TableCell>{entity?.phoneNumber || "-"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:organizationNumber")}</TableCell>
                <TableCell>{entity?.organizationNumber || "-"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:description")}</TableCell>
                <TableCell>{entity?.description || "-"}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.fieldTitle}>{t("contact:contactInformationCard:standardAgreement")}</TableCell>
                <TableCell>{entity?.standardAgreement?.name || "-"}</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
          {
            entity?.isCustomer === false ? (
              <Grid item>
                <Button variant="contained" color="neutral" size="medium" onClick={addCustomerInformation} startIcon={<AddIcon/>}>
                  {t("contact:contactInformationCard:addCustomerInformation")}
                </Button>
              </Grid>
            ) : null
          }

          <Grid item>
            <Button variant="contained" color="neutral" size="medium" onClick={() => setDialog(true)}>
              {t("contact:contactInformationCard:edit")}
            </Button>
          </Grid>

        </Grid>
      </Grid>

      {dialog ? (
        <EntityEditDialog
          editTitle={t("contact:contactInformationCard:editDialogTitle")}
          entity={entity}
          fields={fields}
          open={dialog}
          onCancel={onEditCancel}
          onUpdate={onEditConfirm}
        />
      ) : null}
    </ToggleSurface>
  );
}

