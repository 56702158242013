import { makeStyles } from "tss-react/mui";
import { Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { LocationOn as LocationOnIcon, Person as PersonIcon } from "@mui/icons-material";
import { useStation } from "../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Location } from "@emberly/zenith-client";
import { FormatLocation, MapboxQueryToLocation } from "../../../common/maphelpers";

const useStyles = makeStyles()(
  (theme) => ({
    input: {
      background: "none !important",
    },
    listbox: {
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);


const FORWARD_PASS = (x) => x;
const IS_OPTION_EQUAL = (a, b) => a.mapboxId === b.mapboxId;

export default function MapboxSearch(props) {
  const { label, value, onChange, disabled, freeSolo } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { station } = useStation();

  const [popupOpen, setPopupOpen] = useState(false);

  const onPopupClose = useCallback(() => setPopupOpen(false), []);

  const onPopupOpen = useCallback(() => {
    setPopupOpen(true);
  }, [inputValue]);


  useEffect(() => {
    setOption(value);
  }, [value, station]);

  useEffect(() => {
    let alive = true;
    const timer = setTimeout(() => {


      if (!!inputValue && option?.title !== inputValue && popupOpen) {

        // TODO session token and proximity and language, used to be "en"
        axios(`https://api.mapbox.com/search/searchbox/v1/suggest?q=${inputValue}&language=no&country=no%2Cse%2Cdk&proximity=ip&session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
          .then((res) => {
            const { suggestions } = res.data;
            // todo might need to do something with attribution here, from res.dat
            if (!alive) return;

            // TODO process data
            setOptions(suggestions.filter(t => t.feature_type === "poi" || t.feature_type === "address" || t.feature_type === "street").map((t, key) => ({
              title: t.name,
              address: t.full_address,
              mapboxId: t.mapbox_id,
              type: t.feature_type,
              isContact: false,
              key
            })));

          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }, 300);

    return () => {
      clearTimeout(timer);
      alive = false;
    };
  }, [inputValue, option, station, popupOpen]);


  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      // TODO session token

      if (option.isContact) {
        onChange(new Location({ ...option }));
      } else {
        axios(`https://api.mapbox.com/search/searchbox/v1/retrieve/${option.mapboxId}?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
          .then(res => {
            const feature = res.data?.features[0];
            onChange(MapboxQueryToLocation(option, feature));
          })
          .catch(err => {
            console.log(err, err);
          });
      }

    } else {
      if (ev?.type !== "change") {
        onChange(new Location({ address: "" }));
      }
    }
  }, [onChange]);


  const onInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);

    if (freeSolo && event?.type === "change") {

      onChange(new Location({
        address: newInputValue,
      }));

    }
  }, [onChange, freeSolo]);

  return (

    <Autocomplete
      onReset={console.log}
      getOptionLabel={FormatLocation}
      isOptionEqualToValue={IS_OPTION_EQUAL}
      options={options}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      autoComplete
      fullWidth
      freeSolo
      clearOnBlur
      filterOptions={FORWARD_PASS}
      includeInputInList
      value={option}
      disabled={disabled}
      noOptionsText={t("common:noResults")}
      onChange={onOptionSelected}
      ListboxProps={{
        className: classes.listbox
      }}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          className={classes.input}
          {...params}
          label={label}
          size="small"
          variant="filled"
          fullWidth
          autoComplete="new-password"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.key}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                <Typography>
                  {option.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.address}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />

  );
}


