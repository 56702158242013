import { useCallback, useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Divider, Grid, List, ListItemText, ListItemButton, Typography, ListItemSecondaryAction, Pagination, LinearProgress, } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../../common/core/ResponsivePage";
import { ToggleSurface } from "../../../common/core/Surface";
import { NavLink } from "react-router-dom";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import { useEntities, Compare, useAuth } from "@emberly/zenith-client";
import EmptyState from "../../../common/core/EmptyState";
import { FullTimeView } from "../../../common/core/Time";
import config from "../../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function MyCashSettlementArchivePage() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { user } = useAuth();

  const [page, setPage] = useState(0);
  const pageSize = 25;

  const { entities: settlements, loading, totalCount } = useEntities(
    "CashSettlement",
    useMemo(() => ({
      path: "sender.id",
      comparer: Compare.EQ,
      value: user.sub
    }), [user?.sub]),
    !!user,
    page,
    pageSize,
    null,
    true
  );

  const showLoading = loading && settlements.length === 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const onPageChange = useCallback((_, next) => {
    setPage(next);
  }, []);

  return (
    <ResponsivePage
      title={t("cashSettlementArchive:title")}
      maxWidth={config.pageSize.tiny}
      backButton
    >

      <ToggleSurface>

        <Grid item xs={12} container spacing={2}>
          {
            settlements.length === 0 && !loading ? (
              <EmptyState
                title={t("cashSettlementArchive:emptyStateTitle")}
                description={t("cashSettlementArchive:emptyStateDescription")}
              />
            ) : (
              <List className={classes.list}>
                {!showLoading ? (
                  settlements.map((settlement, index) => <SettlementItem settlement={settlement} key={index} />)
                ) : (
                  <LinearProgress variant="indeterminate" />
                )}
              </List>

            )
          }

        </Grid>

        {
          totalPages > 1 ? (
            <Grid item xs={12}>
              <Pagination size="small" count={totalPages} page={page + 1} onChange={onPageChange} />
            </Grid>
          ) : null
        }

      </ToggleSurface>

    </ResponsivePage >
  );
}

function SettlementItem(props) {
  const { settlement } = props;
  const { t } = useTranslation();

  return (
    <NavLink to={`./${settlement.id}`}>
      <ListItemButton
      >
        <ListItemText
          primary={<FullTimeView time={settlement.created} isDate />}
          secondary={`${FixNumber(settlement.totalSum.value)} ${getCurrencyShorthand(t, settlement.totalSum.currency)} - ${settlement.sender.name}`}
        />
        <ListItemSecondaryAction>
          <Typography variant="body2" color="textSecondary">
            #{settlement.number}
          </Typography>
        </ListItemSecondaryAction>
      </ListItemButton>
      <Divider />
    </NavLink>
  );
}
