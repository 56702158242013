import { makeStyles } from "tss-react/mui";
import { Grid, Typography, } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";
import FileImportSection from "./common/FileImportSection";
import { useCallback, useMemo } from "react";
import { GeoCodeList } from "../../../common/maphelpers";
import { makeId } from "@emberly/zenith-client";

const useStyles = makeStyles()(
  (theme) => ({
    text: {
      "& a": {
        color: "inherit"
      },
    },
  })
);

const T_CONF = {
  TemplateLink: <a target="_blank" rel="noreferrer noopener" href="https://vg.no">link</a>,
  HelpLink: <a target="_blank" rel="noreferrer noopener" href="https://vg.no">link</a>,
};

export default function ContactImportPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("entity:contact:name"),
        flex: 1,
        minWidth: 100
      },
      {
        field: "organizationNumber",
        headerName: t("entity:contact:organizationNumber"),
        flex: 1,
        minWidth: 100
      },
      {
        field: "location.address",
        name: "address1",
        headerName: t("entity:contact:address1"),
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.location?.address || "-",
      },
      {
        field: "address2",
        headerName: t("entity:contact:address2"),
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.location?.address2 || "-",
      },
      {
        field: "location.postCode",
        name: "postNumber",
        headerName: t("entity:contact:postNumber"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.location?.postCode || "-",
      },
      {
        field: "location.place",
        name: "area",
        headerName: t("entity:contact:area"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.location?.place || "-",
      },
      {
        field: "location.country",
        name: "country",
        headerName: t("entity:contact:country"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.location?.country || "-",
      },
      {
        field: "email",
        headerName: t("entity:contact:email"),
        flex: 1,
        minWidth: 160
      },
      {
        field: "phoneNumber",
        headerName: t("entity:contact:phoneNumber"),
        flex: 1,
        minWidth: 100
      },
      {
        field: "description",
        headerName: t("entity:contact:description"),
        flex: 1,
        minWidth: 100
      },
      {
        field: "customerNumber",
        headerName: t("entity:contact:customerNumber"),
        flex: 1,
        minWidth: 100
      },
      {
        field: "billing.address",
        name: "invoiceAddress1",
        headerName: t("entity:contact:invoiceAddress1"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.billing?.address || "-",
      },
      {
        field: "billing.address2",
        name: "invoiceAddress2",
        headerName: t("entity:contact:invoiceAddress2"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.billing?.address2 || "-",
      },
      {
        field: "billing.area",
        name: "invoiceArea",
        headerName: t("entity:contact:invoiceArea"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.billing?.area || "-",
      },
      {
        field: "billing.postNumber",
        name: "invoicePostNumber",
        headerName: t("entity:contact:invoicePostNumber"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.billing?.postNumber || "-",
      },
      {
        field: "billing.country",
        name: "invoiceCountry",
        headerName: t("entity:contact:invoiceCountry"),
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.row.billing?.country || "-",
      },
      {
        field: "billing.email",
        name: "invoiceEmail",
        headerName: t("entity:contact:invoiceEmail"),
        flex: 1,
        minWidth: 160,
        valueGetter: (params) => params.row.billing?.email || "-",
      },
    ]
  }, [t]);

  const onMapRow = useCallback(async (row, key) => {
    const customerNumber = !!row.customerNumber ? parseInt(row.customerNumber) : -1;
    const isCustomer = !!row.customerNumber && !Number.isNaN(customerNumber);

    return {
      id: row.id,
      name: row.name,
      phoneNumber: row.phoneNumber,
      email: row.email,
      organizationNumber: row.organizationNumber,
      customerNumber: Number.isNaN(customerNumber) ? -1 : customerNumber,
      description: row.description,
      location: {
        id: makeId(),
        address: row.address1,
        address2: row.address2,
        postCode: row.postNumber,
        country: row.country,
        place: row.area,
      },
      isCustomer,
      billing: isCustomer ? {
        country: row.invoiceCountry,
        address: row.invoiceAddress1,
        address2: row.invoiceAddress2,
        email: row.invoiceEmail,
        postNumber: row.invoicePostNumber,
        area: row.invoiceArea
      } : undefined
    };
  }, [t]);

  const onProcessEntities = useCallback(async (entities) => {
    const locations = entities.filter(t => !!t.location.address).map(t => t.location);
    const result = await GeoCodeList(locations);
    
    for (let i = 0; i < result.length; i++) {
      locations[i].coordinates = result[i];
    }
  }, []);

  return (
    <ResponsivePage
      maxWidth={config.pageSize.compact}
      backButton
      title={t("importPage:contacts:header")}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary" className={classes.text}>
          <Trans
            i18nKey="importPage:contacts:description"
            components={T_CONF}
          />
        </Typography>
      </Grid>

      <FileImportSection
        name="contact_import"
        type="Contact"
        columns={columns}
        onMapRow={onMapRow}
        entityName={t("importPage:contacts:entityName")}
        onProcessEntities={onProcessEntities}
      />

    </ResponsivePage>
  );
}

