
import { Divider, Avatar, Typography, CardHeader, IconButton, FormControlLabel, Switch, FormGroup, Menu, MenuItem, Slide } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../../providers/DeviceProvider";
import { useCallback, useState } from "react";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { useAuth, useOrganizations, Task } from "@emberly/zenith-client";
import ConfirmDialog from "../inputs/ConfirmDialog";
import SelectDialog from "../inputs/SelectDialog";
import AccountSettingsDialog from "./AccountSettingsDialog";
import { useStation } from "../../../providers/StationProvider";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: theme.spacing(1.5),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      transition: "box-shadow 0.25s",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px"
    },
    rootShadow: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)"
    },
    switchLabel: {
      width: `calc(100% - ${theme.spacing(1.5)})`,
      justifyContent: "space-between",
      marginLeft: theme.spacing(1.5),
      marginBottom: theme.spacing(1)
    },
    switch: {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.neutral.dark
      }
    },
    cardText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflowX: "hidden"
    },
    card: {
      padding: theme.spacing(1),
      width: "100%",
      alignItems: "flex-start",
      "& .MuiCardHeader-content": {
        overflow: "hidden"
      }
    },
    avatar: {
      marginTop: theme.spacing(0.5),
    }
  })
);


export default function SidebarBottomCard(props) {
  const { classes } = useStyles();
  const { slim, isMobile } = useDevice();
  const { setShowActivityLog, showActivityLog } = useStation();
  const { t } = useTranslation();
  const { shadow, compactMode, compactLayoutReady } = props;


  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(ev => setAnchorEl(ev.currentTarget), []);

  // switch station action
  const [switchStationAction, setSwitchStationAction] = useState(null);


  // org data
  const { user, logout, switchOrganization } = useAuth();
  const { organizations } = useOrganizations(!!switchStationAction || open);
  const { station } = useStation();

  const onSwitchStation = useCallback(async () => {
    handleClose();
    const t = new Task();
    setSwitchStationAction(t);

    try {
      const newOrgId = await t.wait();
      switchOrganization(newOrgId);
    } catch {
      setSwitchStationAction(null);
    }
  }, [switchOrganization, handleClose]);


  // logout action
  const [logoutAction, setLogoutAction] = useState(null);

  const onLogout = useCallback(async () => {
    handleClose();
    const t = new Task();
    setLogoutAction(t);

    try {
      await t.wait();
      await logout({
        returnTo: window.location.origin
      });
    } catch {
      setLogoutAction(null);
    }
  }, [logout, handleClose]);



  // account settings
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(false);
  const onOpenAccountSettings = useCallback(() => {
    handleClose();
    setAccountSettingsOpen(true);
  }, [handleClose]);

  const onCloseAccountSettings = useCallback(() => setAccountSettingsOpen(false), [])

  const onToggleActivityLog = useCallback(ev => {
    setShowActivityLog(ev.target.checked)
  }, [setShowActivityLog]);

  const showAsCompact = !compactMode && !compactLayoutReady;

  return (
    <FormGroup aria-label="position" row className={`${classes.root} ${shadow && !compactMode ? classes.rootShadow : ""}`}>

      {!slim && showAsCompact ? (
        <FormControlLabel
          className={classes.switchLabel}
          control={<Switch className={classes.switch} color="primary" size="small" checked={showActivityLog} onChange={onToggleActivityLog} />}
          label={
            <Typography variant="body2" fontWeight="500" className={classes.cardText}>
              {t("sidebar:bottomCard:showLog")}
            </Typography>
          }
          labelPlacement="start"
        />
      ) : null}

      <CardHeader
        className={classes.card}
        avatar={
          <Avatar src={user?.picture} alt={user?.email} className={classes.avatar} onClick={compactMode ? handleOpen : undefined} disabled={!user || !organizations} />
        }
        action={
          showAsCompact ? (
            <IconButton aria-label="settings" onClick={handleOpen} disabled={!user || !organizations}>
              <MoreVertIcon />
            </IconButton>
          ) : null
        }
        title={
          showAsCompact ? (
            <Typography variant="body2" fontWeight="500" className={classes.cardText}>
              {user?.name || "-"}
            </Typography>
          ) : null
        }
        subheader={
          showAsCompact ? (
            <Typography variant="body2" color="textSecondary" className={classes.cardText}>
              {isMobile ? <>{user?.email || "-"}<br /></> : null}
              {station?.displayName || "-"}
            </Typography>
          ) : null
        }
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onOpenAccountSettings}>{t("sidebar:bottomCard:accountSettings")}</MenuItem>
        <MenuItem onClick={onSwitchStation}>{t("sidebar:bottomCard:switchStationDialog:action")}</MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>{t("sidebar:bottomCard:logoutDialog:action")}</MenuItem>
      </Menu>

      <ConfirmDialog
        title={t("sidebar:bottomCard:logoutDialog:title")}
        description={t("sidebar:bottomCard:logoutDialog:description")}
        task={logoutAction}
        confirmText={t("sidebar:bottomCard:logoutDialog:action")}
      />

      <SelectDialog
        title={t("sidebar:bottomCard:switchStationDialog:title")}
        task={switchStationAction}
        confirmText={t("sidebar:bottomCard:switchStationDialog:action")}
        selectLabel={t("sidebar:bottomCard:switchStationDialog:selectLabel")}
        selectOptions={organizations}
        defaultValue={user?.org_id}
      />

      {
        accountSettingsOpen ? (
          <AccountSettingsDialog
            onClose={onCloseAccountSettings}
          />
        ) : null
      }

    </FormGroup>
  );
}

