import { createContext, useCallback, useContext, useState } from "react";
import { useIsPortrait } from "../hooks/useIsPortrait";
import { isIOS, isMobile } from "react-device-detect";
import useWindowSize from "../hooks/useWindowSize";

const currentLocation = new URL(window.location.href);

export const isEmbed = inIframe() || currentLocation.searchParams.has("embed");

const verifyMobile = () => {
  try {
    return isMobile || !!(navigator.userAgent || navigator.vendor || window.opera).match(/Mobile DuckDuckGo/);
  } catch (err) {
    return false;
  }
};

const verifiedIsMobile = verifyMobile();
const verifiedIsIos = isIos();

export const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

export const DeviceProvider = (props) => {
  const { width } = useWindowSize();
  const isPortrait = useIsPortrait();
  const isMobilePortraitView = !isEmbed && isPortrait && verifiedIsMobile;
  const slim = width <= 960 || isMobilePortraitView;

  const [sidebarOpen, setSidebarOpen] = useState(!slim);
  const [compactMode, _setCompactMode] = useState(LocalIsCompactMode());

  const setCompactMode = useCallback((t) => {
     _setCompactMode(cm => {
        let value = typeof t === "function" ? t(cm) : t;
        LocalSetCompactMode(value);
        return value;
     });
  }, [_setCompactMode]);

  return (
    <DeviceContext.Provider
      value={{
        isMobilePortraitView,
        slim,
        isDesktopView: !isEmbed && !(isPortrait && verifiedIsMobile),
        isEmbedView: isEmbed,
        isEmbed: isEmbed,
        isMobilePortrait: isMobile && isPortrait,
        isPortrait: isPortrait,
        isMobile: verifiedIsMobile,
        isIos: verifiedIsIos,
        screenWidth: width,
        sidebarOpen,
        setSidebarOpen,
        compactMode: compactMode && !slim,
        setCompactMode
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};



function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isIos() {
  if (isIOS || /iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return navigator.maxTouchPoints > 1 && /MacIntel/.test(navigator.platform);
  }
}


function LocalIsCompactMode() {
  try {
    return localStorage.getItem("assist-compactmode") === "y";
  } catch (err) {
    console.log(err);
    return false;
  }
}

function LocalSetCompactMode(cm) {
  try {
    localStorage.setItem("assist-compactmode", !!cm ? "y" : "n");
  } catch (err) {
    console.log(err);
  }
  return cm;
}
