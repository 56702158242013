import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { useMission } from "../../../../providers/MissionProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { Delete as DeleteIcon } from "@mui/icons-material";
import SubPageEmptyState from "../common/SubPageEmptyState";
import { useMemo } from "react";
import StorageSection from "../../../common/sections/StorageSection";
import config from "../../../../config";

export default function MissionTaskStoragePage() {
  const { t } = useTranslation();
  const { mission, updateMissionField, makeStorageTask, locked } = useMission();
  const storageTask = mission.storageTask;

  const menuItems = useMemo(() => [
    {
      label: t("taskStoragePage:delete"),
      icon: <DeleteIcon />,
      onClick: () => {        
        updateMissionField("storageTask", null);
      }
    },
  ], [t, updateMissionField]);

  return (
    <ResponsiveSubPage
      title={t("taskStoragePage:title")}
      maxWidth={config.pageSize.small}
      backPath="../"
      menuItems={!!storageTask && !locked ? menuItems : undefined}
      backButton
    >
      <ToggleSurface xs={12}>

        {!storageTask ? (
          <SubPageEmptyState
            title={t("taskStoragePage:emptyState:title")}
            description={t("taskStoragePage:emptyState:description")}
            buttonText={t("taskStoragePage:emptyState:buttonText")}
            onClick={makeStorageTask}
            disabled={locked}
          />
        ) : (
          <StorageSection />
        )}

      </ToggleSurface>
    </ResponsiveSubPage>
  );
}
