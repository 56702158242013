import { useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MissionEnumsLists } from "../../../common/constants";
import EntityTable from "../../common/tables/EntityTable";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useStation } from "../../../providers/StationProvider";
import { Chip, Grid } from "@mui/material";
import { FullTimeView } from "../../common/core/Time";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      paddingBottom: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    grid: {
      maxWidth: "1600px"
    },
    driverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px"
    },
    noDriverName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginLeft: "2px",
      color: theme.palette.text.secondary
    }
  })
);

export default function AllMissionsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createMissionDraft } = useStation();

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("missionsArchive:table:mission"),
        flex: 0
      },
      {
        field: "state",
        minWidth: 120,
        type: "singleSelect",
        headerName: t("common:tables:state"),
        valueGetter: (params) => params.row.state,
        valueFormatter: (params) => t(`mission:enums:state:${MissionEnumsLists.State[params.value]}`),
        valueOptions: MissionEnumsLists.State.map((_, i) => ({
          value: i,
          label: t(`mission:enums:state:${MissionEnumsLists.State[i]}`),
        }))
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        type: "dateTime",
        renderCell: (params) => <FullTimeView time={params.value} />,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "details.type",
        headerName: t("common:tables:type"),
        flex: 1,
        minWidth: 120,
        type: "singleSelect",
        valueGetter: (params) => params.row.details.type,
        valueFormatter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.value]}`),
        valueOptions: MissionEnumsLists.Type.map((_, value) => ({
          value,
          label:  t(`mission:enums:type:${MissionEnumsLists.Type[value]}`)
        }))
      },
      {
        field: "details.cause.name",
        headerName: t("common:tables:cause"),
        valueGetter: (params) => params.row.details?.cause?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "actors.contract.agreement.name",
        headerName: t("common:tables:agreement"),
        valueGetter: (params) => params.row.actors?.contract?.agreement?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "details.tags.name",
        headerName: t("common:tables:tags"),
        valueGetter: params => params.row.details?.tags?.map(t => t.name).join() || "",
        flex: 0.5,
        minWidth: 100,
        renderCell: params => (
          <Grid container spacing={0.5}>
            {params.row.details?.tags?.map((tag, key) => <Grid item key={key}><Chip label={tag.name} size="small" /></Grid>)}
          </Grid>
        )
      },
      {
        field: "target.carDetails.make||target.boatDetails.make",
        headerName: t("common:tables:make"),
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details?.make || "-";
        },
      },
      {
        field: "target.carDetails.model||target.boatDetails.model",
        headerName: t("common:tables:model"),
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details?.model || "-";
        },
      },
      {
        field: "target.carDetails.registration||target.boatDetails.registration",
        headerName: t("common:tables:registration"),
        valueGetter: params => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details?.registration || "-"
        },
        minWidth: 100,
        flex: 1
      },
      {
        field: "actors.requester.name||actors.owner.name",
        headerName: t("missionsArchive:table:requester"),
        valueGetter: (params) => params.row.actors.ownerIsNotRequester ? params.row.actors.requester.name : params.row.actors.owner.name,
        flex: 1,
        minWidth: 120,
      },
    ]
  }, [t, classes]);

  return (
    <ResponsivePage
      title={t("missionsArchive:title")}
      secondaryAction={t("missionsArchive:createMission")}
      onSecondaryAction={createMissionDraft}
      maxWidth={config.pageSize.full}
    >
      <EntityTable
        tableId="all_missions"
        type="Mission"
        columns={columns}
        onRowClick={onRowClick}
        emptyStateTitle={t("missionsArchive:emptyStateTitle")}
        emptyStateDescription={t("missionsArchive:emptyStateDescription")}
        sortDescendingByDefault
        serverSidePaging
        xs={12}
      />
    </ResponsivePage>
  );
}