import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";
import config from "../../../config";

export default function CauseRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "title",
        headerName: t("cause:fieldTitle"),
        flex: 1
      }
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("cause:fieldTitle"), path: "title", type: EntityFieldTypes.String, required: true }
  ], [t])

  return (
    <ResponsivePage
      title={t("cause:title")}
      maxWidth={config.pageSize.small}
    >
      <EntityTable
        tableId="causes"
        type="Cause"
        columns={columns}
        fields={fields}
        createTitle={t("cause:create")}
        editTitle={t("cause:edit")}
        emptyStateTitle={t("cause:emptyStateTitle")}
        emptyStateDescription={t("cause:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}