import { makeStyles } from "tss-react/mui";
import { Grid, List, Typography, } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import SectionItem from "../../common/inputs/SectionItem";
import config from "../../../config";


const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function ImportPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <ResponsivePage
      maxWidth={config.pageSize.small}
      title={t("importPage:header")}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">{t("importPage:description")}</Typography>
      </Grid>

      <ToggleSurface xs={12}>
        <List className={classes.list}>

          <SectionItem
            to="contacts"
            primary={t("importPage:importContactsTitle")}
            secondary={t("importPage:importContactsDescription")}
          />

          <SectionItem
            to="products"
            primary={t("importPage:importProductsTitle")}
            secondary={t("importPage:importProductsDescription")}
          />

        </List>

      </ToggleSurface>

    </ResponsivePage>
  );
}

