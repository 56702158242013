import { Dialog } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDevice } from "../../../providers/DeviceProvider";


const useStyles = makeStyles()(
  (theme) => ({
    fullScreen: {
      "& .MuiPaper-root": {
        borderRadius: 0
      }
    }
  })
);

export default function ResponsiveDialog(props) {
  const { open, fullWidth, maxWidth, onClose, fullScreen, onKeyUp } = props;
  const { classes } = useStyles();
  const { isMobilePortraitView } = useDevice()

  const full = isMobilePortraitView || fullScreen;


  return (
    <Dialog
      onKeyUp={onKeyUp}
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      fullScreen={full}
      maxWidth={maxWidth}
      className={full ? classes.fullScreen : undefined}
    >
      {props.children}
    </Dialog>
  );
}


