import { makeStyles } from "tss-react/mui";
import { Grid, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import { EditLocationAlt as HasCoordinatesIcon, EditLocationAltOutlined as NoCoordinatesIcon } from "@mui/icons-material";
import AddressPickerDialog from "./pickers/AddressPickerDialog";
import MapboxSearch from "./MapboxSearch";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      background: theme.palette.common.grey.input,
      position: "relative",
      borderRadius: "8px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent !important"
      },
      "&:hover": {
        "&:after": {
          zIndex: 3,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          background: theme.palette.action.hover,
          borderRadius: "8px",
          content: "''",
          display: "block",
          pointerEvents: "none"
        },
        "& .MuiInputBase-root": {
          backgroundColor: "transparent !important"
        }
      }
    },
    pickerButton: {
      marginRight: theme.spacing(1)
    },
  })
);

export default function AddressPicker(props) {
  const { picker, label, value, onChange, disabled, freeSolo } = props;
  const { classes } = useStyles();

  // Dialog
  const [pickerOpen, setPickerOpen] = useState(false);

  const onPickerClose = useCallback(() => {
    setPickerOpen(false);
  }, []);

  const onPickerConfirm = useCallback((location) => {
    setPickerOpen(false);
    onChange(location);
  }, [onChange]);


  return (
    <Grid container item xs direction="row" className={classes.root} justifyContent="flex-start" alignItems="center">

      <Grid item xs>
        <MapboxSearch
          label={label}
          onChange={onChange}
          freeSolo={freeSolo}
          value={value}
          disabled={disabled}
        />
      </Grid>

      {
        !!picker ? (
          <Grid item>
            <IconButton size="small" className={classes.pickerButton} onClick={() => setPickerOpen(true)} disabled={disabled}>
              {!!value?.coordinates?.length ? <HasCoordinatesIcon /> : <NoCoordinatesIcon />}
            </IconButton>
            {
              pickerOpen ? (
                <AddressPickerDialog value={value} onCancel={onPickerClose}  onConfirm={onPickerConfirm} />
              ) : null
            }
          </Grid>
        ) : null
      }

    </Grid>
  );
}


