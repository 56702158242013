import { useMemo, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { Compare, EntityFieldTypes } from "@emberly/zenith-client";
import { useNavigate, useParams } from "react-router-dom";
import ResponsivePage from "../../common/core/ResponsivePage";
import { Grid, Tabs, Tab } from "@mui/material";
import config from "../../../config";


const useStyles = makeStyles()(
  (theme) => ({
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  })
);


const IsCustomerFilter = {
  name: "contact_isCustomer",
  value: true,
  path: "isCustomer",
  comparer: Compare.EQ
}


export default function ContactRegister() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { tabId } = useParams();
  const { classes } = useStyles();


  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("contactRegister:fieldNumber"),
        flex: 0.5,
        minWidth: 80
      },
      {
        field: "name",
        headerName: t("contactRegister:fieldName"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "organizationNumber",
        headerName: t("contactRegister:fieldOrganizationNumber"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "address",
        headerName: t("contactRegister:fieldAddress"),
        flex: 1,
        minWidth: 120,
        valueGetter: params => params.row.location?.address || "-",
      },
      {
        field: "email",
        headerName: t("contactRegister:fieldEmail"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "phoneNumber",
        headerName: t("contactRegister:fieldPhoneNumber"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "description",
        headerName: t("contactRegister:fieldDescription"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "customerNumber",
        headerName: t("contactRegister:fieldCustomerNumber"),
        type: "number",
        flex: 1,
        minWidth: 120,
        valueGetter: params => params.row?.customerNumber,
        valueFormatter: params => params.value || ""
      },
      {
        field: "billingAddress",
        headerName: t("contactRegister:fieldBillingAddress"),
        flex: 1,
        minWidth: 120,
        valueGetter: params => params.row.billing.address || "-"
      },
      {
        field: "billingEmail",
        headerName: t("contactRegister:fieldBillingEmail"),
        flex: 1,
        minWidth: 120,
        valueGetter: params => params.row.billing.email || "-"
      },
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("contactRegister:fieldContactHeader"), type: EntityFieldTypes.Header },
    { name: t("contactRegister:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("contactRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Address },
    { name: t("contactRegister:fieldEmail"), path: "email", type: EntityFieldTypes.String, dataType: "email" },
    { name: t("contactRegister:fieldPhoneNumber"), path: "phoneNumber", type: EntityFieldTypes.String },
    { name: t("contactRegister:fieldOrganizationNumber"), path: "organizationNumber", type: EntityFieldTypes.String },
    { name: t("contactRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
    { name: t("contactRegister:fieldStandardAgreement"), path: "standardAgreement", type: EntityFieldTypes.Search, entityType: "Agreement", searchPath: "name", valueGetter: v => ({ id: v.id, name: v.name }) },
    { type: EntityFieldTypes.Divider },
    { name: t("contactRegister:fieldCustomerHeader"), type: EntityFieldTypes.Header },
    { name: t("contactRegister:fieldIsCustomer"), path: "isCustomer", type: EntityFieldTypes.Boolean },
    { name: t("contactRegister:fieldCustomerNumber"), path: "customerNumber", type: EntityFieldTypes.String, dataType: "number", numberType: "int", serialization: "number", activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingCountry"), path: "billing.country", type: EntityFieldTypes.Country, activeIfPath: "isCustomer", defaultValue: i18n.language.toUpperCase() },
    { name: t("contactRegister:fieldBillingAddress"), path: "billing.address", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingAddress2"), path: "billing.address2", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldPostNumber"), path: "billing.postNumber", type: EntityFieldTypes.String, xs: 5, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldArea"), path: "billing.area", type: EntityFieldTypes.String, xs: 7, activeIfPath: "isCustomer" },
    { name: t("contactRegister:fieldBillingEmail"), path: "billing.email", type: EntityFieldTypes.String, activeIfPath: "isCustomer", dataType: "email" },
  ], [t, i18n])

  const onCreated = useCallback((entity) => {
    navigate(!!tabId ? `./${entity.id}/details` : `./all/${entity.id}/details`, { relative: "path" });
  }, [navigate, tabId]);

  const onRowClick = useCallback((params, ev) => {
    navigate( !!tabId ? `./${params.id}/details` : `./all/${params.id}/details`, { relative: "path" });
  }, [navigate, tabId]);

  const onTabChange = useCallback((_, val) => navigate(!!tabId ? `../${val}` : `./${val}`, { relative: "path", replace: true }), [navigate, tabId]);

  return (
    <ResponsivePage
      title={t("contactRegister:title")}
      maxWidth={config.pageSize.large}
    >

      <Grid item xs={12}>
        <Tabs className={classes.tabs} value={tabId || "all"} onChange={onTabChange}>
          <Tab value="all" label={t("contactRegister:all")} />
          <Tab value="customers" label={t("contactRegister:customers")} />
        </Tabs>
      </Grid>

      {
        !tabId || tabId === "all" ? (
          <EntityTable
            type="Contact"
            tableId="contacts_all"
            columns={columns}
            fields={fields}
            createTitle={t("contactRegister:create")}
            editTitle={t("contactRegister:edit")}
            emptyStateTitle={t("contactRegister:emptyStateTitle")}
            emptyStateDescription={t("contactRegister:emptyStateDescription")}
            onRowClick={onRowClick}
            createButton
            onCreated={onCreated}
          />
        ) : tabId === "customers" ? (
          <EntityTable
            type="Contact"
            tableId="contacts_customers"
            columns={columns}
            fields={fields}
            filter={IsCustomerFilter}
            createTitle={t("contactRegister:create")}
            editTitle={t("contactRegister:edit")}
            emptyStateTitle={t("contactRegister:emptyStateTitle")}
            emptyStateDescription={t("contactRegister:emptyStateDescription")}
            onRowClick={onRowClick}
            createButton
            onCreated={onCreated}
          />
        ) : null
      }

    </ResponsivePage>
  );
}