import { Location } from "@emberly/zenith-client";
import axios from "axios";
import mapboxgl from "mapbox-gl";

export function GeoJsonPoint(coordinates, properties = {}) {
  return {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": coordinates
    },
    "properties": {
      ...properties
    }
  };
}

export function GetBounds(list) {
  const bounds = new mapboxgl.LngLatBounds();
  const len = list.length;

  for (let i = 0; i < len; i++) {
    bounds.extend(list[i].geometry.coordinates);
  }

  return bounds;
}

export function MapboxPickerToLocation(coordinates, feature) {
  const context = feature.context;

  const place = context.find(t => t.id?.startsWith("place."))?.text || feature.text || null;
  const postCode = context.find(t => t.id?.startsWith("postcode."))?.text || null;
  const region = context.find(t => t.id?.startsWith("region."))?.text || null;
  const country = context.find(t => t.id?.startsWith("country."))?.properties?.short_code?.toUpperCase() || null;

  const placeName = feature.place_name;
  const address = GetPlaceName(feature);

  return new Location({
    mapboxId: feature.properties?.mapbox_id,
    type: feature.place_type[0] || "address",
    title: placeName,
    address,
    coordinates,
    postCode,
    region,
    place,
    country,
  });
}

function GetPlaceName(feature) {
  let p = feature.place_name;

  if (!p) return "";

  const idx = p.indexOf(",");

  if (idx === -1) {
    return p;
  } else {
    return p.substring(0, idx);
  }
}

export function MapboxQueryToLocation(option, feature) {
  const coordinates = feature?.geometry?.coordinates || null;
  const context = feature?.properties?.context;

  const address = context?.address?.name || option?.address || feature?.name || null;
  const place = context?.place?.name || null;
  const region = context?.region?.name || null;
  const country = context?.country?.country_code || null;
  const postCode = context?.postcode?.name || null;

  return new Location({
    ...option,
    address,
    place,
    region,
    country,
    postCode,
    coordinates
  });
}


export function FormatLocation(option) {
  if (!option) return "";
  return (
    typeof option === "string" ? option : !option.title ? option.address : (option.address?.startsWith(option.title) ? option.address : option.title.startsWith(option.address) ? option.title : `${option.title}${!!option.address ? " - " : ""}${option?.address || ""}`)
  ) || "";
}



export function GeolocationDistance(g0, g1) {
  if (!g0?.coords?.latitude || !g0?.coords?.longitude || !g1?.coords?.latitude || !g1?.coords?.longitude) return 0;
  const dist = HaversineDistance(g0.coords.longitude, g0.coords.latitude, g1.coords.longitude, g1.coords.latitude);
  const g0Acc = g0.coords.accuracy || 0;
  const g1Acc = g1.coords.accuracy || 0;
  return Math.max(0, dist - g0Acc - g1Acc);
}


export function HaversineDistance(lon1, lat1, lon2, lat2) {

  const R = 6371e3; // metres
  const theta1 = lat1 * Math.PI / 180.0;
  const theta2 = lat2 * Math.PI / 180.0;
  const dTheta = (lat2 - lat1) * Math.PI / 180.0;
  const dLon = (lon2 - lon1) * Math.PI / 180.0;

  const a = (
    Math.sin(dTheta / 2) * Math.sin(dTheta / 2) +
    Math.cos(theta1) * Math.cos(theta2) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  );

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // In meters
}



export function GroupByDistance(inputList, minDistanceMeters, propFn) {
  const list = [...inputList];
  const result = [];

  for (let i = 0; i < list.length; i++) {
    const group = list[i];
    if (!group) continue;

    const groupCoords = propFn(group);

    if (!groupCoords || groupCoords?.length < 2) continue;

    let resultItem = [group];

    for (let j = i + 1; j < list.length; j++) {
      const elem = list[j];
      if (!elem) continue;

      const elemCoords = propFn(elem);

      if (!elemCoords || elemCoords?.length < 2) continue;

      let distance = HaversineDistance(groupCoords[0], groupCoords[1], elemCoords[0], elemCoords[1]);

      if (distance < minDistanceMeters) {
        resultItem.push(elem);
        list[j] = null;
      }
    }

    result.push(resultItem);
  }

  return result;
}



export async function GeoCode(l) {

  try {

    const query = [
      !!l.address ? `address_line1=${l.address}` : null,
      !!l.postCode ? `postcode=${l.postCode}` : null,
      !!l.place ? `place=${l.place}` : null,
      !!l.country ? `country=${l.country}` : null,
    ].filter(t => !!t).join("&")

    const res = await axios(`https://api.mapbox.com/search/geocode/v6/forward?access_token=${mapboxgl.accessToken}&${query}&limit=1&types=address&language=no`)

    if (!!res.data) {
      return res.data?.features?.[0]?.geometry?.coordinates || null;
    }

    return null;

  } catch (err) {
    console.log(err);
    return null;
  }
}


let MAX_BATCH_SIZE = 1000;

export async function GeoCodeList(locations) {

  const query = locations.map(l => ({
    types: ["address"],
    q: [l.address, l.postCode, l.place, l.country].filter(t => !!t).join(", "),
    limit: 1,
  }))

  if (query.length <= MAX_BATCH_SIZE) {
    return await GeoCodeBatch(query);
  } else {

    let queryBatches = [];
    let buffer = [];

    for (let i = 0; i < query.length; i++) {
      buffer.push(query[i]);

      if (buffer.length === MAX_BATCH_SIZE) {
        queryBatches.push(buffer);
        buffer = [];
      }
    }

    if (buffer.length !== 0) {
      queryBatches.push(buffer);
    }

    let result = [];

    for (let i = 0; i < queryBatches.length; i++) {
      let batch = await GeoCodeBatch(queryBatches[i]);
      result = result.concat(batch);
    }

    return result;
  }
}


async function GeoCodeBatch(locations) {

  try {
    const res = await axios.post(`https://api.mapbox.com/search/geocode/v6/batch?access_token=${mapboxgl.accessToken}`, locations);
    const data = res.data;
    return data?.batch?.map(b => b?.features?.[0]?.geometry?.coordinates || null);
  }
  catch (err) {
    console.log(err);
    return locations.map(t => null);
  }
}

