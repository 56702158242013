import { TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../../common/helpers";

export default function EmailTextField(props) {
  const { disabled, label, value, onChange } = props;
  const { t } = useTranslation();
  
  const [blurred, setBlurred] = useState(false);
  
  const onBlur = useCallback(() => setBlurred(true), []);
  const onFocus = useCallback(() => setBlurred(false), []);

  const hasError = useMemo(() => {
    return !!blurred && !!value && !validateEmail(value);
  }, [blurred, value]);

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      variant="filled"
      fullWidth
      size="small"
      helperText={hasError ? t("common:invalidEmail") : undefined}
      error={hasError}
    />
  );
}