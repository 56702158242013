import { Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Person as PersonIcon, PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { useSearch, Search, EntityFieldTypes, useEntityFunctions } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import EntityEditDialog from "./EntityEditDialog";
import { getTextSearch } from "../../../common/search";

const useStyles = makeStyles()(
  (theme) => ({
    listbox: {
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);

const ContactQuery = (inputValue, customer) => !!customer ? {
  must: [
    getTextSearch("name", inputValue),
    {
      path: "isCustomer",
      function: Search.EQUALS,
      value: true
    },
  ]
} : getTextSearch("name", inputValue);

const TEXTFIELD_SX = {
  "&:hover": {
    backgroundColor: "transparent"
  }
};

const GetOptionLabel = (option) => typeof option === 'string' ? option : option.name;

const IsOptionEqualToValue = (a, b) => a.id === b.id;


export default function ContactPicker(props) {
  const { label, value, onChange, disabled, customer, initialValues, fetchInitialValues } = props;
  const [create, setCreate] = useState(false);
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { createEntity } = useEntityFunctions("Contact");

  const [defaultValues, setDefaultValues] = useState({});
  const [popupOpen, setPopupOpen] = useState(false);
  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const [creating, setCreating] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  if (!!value && !option) {
    setOption(value);
  } else if (!!value && !!option && (value.id !== option.id || value.name !== option.name)) {
    setOption(value);
  }

  useEffect(() => {
    if (popupOpen) {

      const timer = setTimeout(
        () => setSearchQuery(inputValue),
        250
      );

      return () => {
        clearTimeout(timer);
      }
    }
  }, [inputValue, popupOpen]);

  const onPopupOpen = useCallback(() => {
    setSearchQuery(inputValue);
    setPopupOpen(true);
  }, [inputValue]);

  const filterQuery = useMemo(() => {
    return ContactQuery(searchQuery, customer);
  }, [customer, searchQuery]);

  const { entities } = useSearch("Contact", filterQuery, popupOpen);

  const filteredEntities = useMemo(() => {
    const result = customer ? entities.filter(t => !!t.billing && !!t.billing.address && !!t.billing.postNumber && !!t.billing.country && !!t.billing.area) : entities;
    if (popupOpen && !!inputValue && !option?.id) {
      return [...result, { name: `${t("contactRegister:addPre")} "${inputValue}" ${t("contactRegister:addPost")}`, isAdd: true, id: "add-new-contact" }];
    } else {
      return result;
    }
  }, [entities, customer, popupOpen, inputValue, t, option]);

  const onBeginCreate = useCallback(ev => {
    ev.preventDefault();

    let iv = typeof fetchInitialValues === "function" ? fetchInitialValues() : initialValues;

    setDefaultValues(!!iv ? { 
      name: inputValue, 
      isCustomer: customer,
      location: iv.location,
      email: iv.email,
      phoneNumber: iv.phoneNumber,
      billing: customer ? {
        country: !!iv.location?.country?.length === 2 ? iv.location.country : "NO",
        address: iv.location?.address || "",
        postNumber: iv.location?.postCode || "",
        area: iv.location?.place || "",
        email: iv.email || ""
      } : undefined
    } : { name: inputValue, isCustomer: customer });
    setCreate(true);
  }, [customer, inputValue, initialValues, fetchInitialValues, customer])

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      onChange(inputValue, option);
    } else {
      onChange(inputValue, null);
    }
  }, [onChange, inputValue]);

  const onInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
    onChange(newInputValue, null);
  }, [onChange]);


  const onPopupClose = useCallback(() => setPopupOpen(false), []);

  const fields = useMemo(() => create ?
    customer ? [
      { name: t("contactRegister:fieldContactHeader"), type: EntityFieldTypes.Header },
      { name: t("contactRegister:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
      { name: t("contactRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Address },
      { name: t("contactRegister:fieldEmail"), path: "email", type: EntityFieldTypes.String, dataType: "email" },
      { name: t("contactRegister:fieldPhoneNumber"), path: "phoneNumber", type: EntityFieldTypes.String },
      { name: t("contactRegister:fieldOrganizationNumber"), path: "organizationNumber", type: EntityFieldTypes.String },
      { name: t("contactRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
      { name: t("contactRegister:fieldStandardAgreement"), path: "standardAgreement", type: EntityFieldTypes.Search, entityType: "Agreement", searchPath: "name", valueGetter: v => ({ id: v.id, name: v.name }) },
      { type: EntityFieldTypes.Divider },
      { name: t("contactRegister:fieldCustomerHeader"), type: EntityFieldTypes.Header },
      { name: t("contactRegister:fieldCustomerNumber"), path: "customerNumber", type: EntityFieldTypes.String, dataType: "number", serialization: "number", numberType: "int", required: true },
      { name: t("contactRegister:fieldBillingCountry"), path: "billing.country", type: EntityFieldTypes.Country, required: true, defaultValue: i18n.language.toUpperCase() },
      { name: t("contactRegister:fieldBillingAddress"), path: "billing.address", type: EntityFieldTypes.String, required: true },
      { name: t("contactRegister:fieldBillingAddress2"), path: "billing.address2", type: EntityFieldTypes.String },
      { name: t("contactRegister:fieldPostNumber"), path: "billing.postNumber", type: EntityFieldTypes.String, xs: 5, required: true },
      { name: t("contactRegister:fieldArea"), path: "billing.area", type: EntityFieldTypes.String, xs: 7, required: true },
      { name: t("contactRegister:fieldBillingEmail"), path: "billing.email", type: EntityFieldTypes.String, dataType: "email" },
    ] : [
      { name: t("contactRegister:fieldContactHeader"), type: EntityFieldTypes.Header },
      { name: t("contactRegister:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
      { name: t("contactRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Address },
      { name: t("contactRegister:fieldEmail"), path: "email", type: EntityFieldTypes.String, dataType: "email" },
      { name: t("contactRegister:fieldPhoneNumber"), path: "phoneNumber", type: EntityFieldTypes.String },
      { name: t("contactRegister:fieldOrganizationNumber"), path: "organizationNumber", type: EntityFieldTypes.String },
      { name: t("contactRegister:fieldDescription"), path: "description", type: EntityFieldTypes.String, multiline: true },
      { name: t("contactRegister:fieldStandardAgreement"), path: "standardAgreement", type: EntityFieldTypes.Search, entityType: "Agreement", searchPath: "name", valueGetter: v => ({ id: v.id, name: v.name }) },
      { type: EntityFieldTypes.Divider },
      { name: t("contactRegister:fieldCustomerHeader"), type: EntityFieldTypes.Header },
      { name: t("contactRegister:fieldIsCustomer"), path: "isCustomer", type: EntityFieldTypes.Boolean },
      { name: t("contactRegister:fieldCustomerNumber"), path: "customerNumber", type: EntityFieldTypes.String, dataType: "number", numberType: "int", serialization: "number", activeIfPath: "isCustomer" },
      { name: t("contactRegister:fieldBillingCountry"), path: "billing.country", type: EntityFieldTypes.Country, activeIfPath: "isCustomer", defaultValue: i18n.language.toUpperCase() },
      { name: t("contactRegister:fieldBillingAddress"), path: "billing.address", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
      { name: t("contactRegister:fieldBillingAddress2"), path: "billing.address2", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
      { name: t("contactRegister:fieldPostNumber"), path: "billing.postNumber", type: EntityFieldTypes.String, xs: 5, activeIfPath: "isCustomer" },
      { name: t("contactRegister:fieldArea"), path: "billing.area", type: EntityFieldTypes.String, xs: 7, activeIfPath: "isCustomer" },
      { name: t("contactRegister:fieldBillingEmail"), path: "billing.email", type: EntityFieldTypes.String, activeIfPath: "isCustomer" },
    ] : null,
    [t, create, customer, i18n]);

  const onCreateEntity = useCallback(async (entityData) => {
    try {
      setCreating(true);
      if (!!entityData) {
        const createdEntity = await createEntity(entityData);
        if (!!createdEntity) {
          setOption(createdEntity);
          onChange(createdEntity.name, createdEntity);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCreating(false);
      setCreate(false);
      setDefaultValues(null);
    }
  }, [createEntity, onChange]);


  return (
    <>
      <Autocomplete
        getOptionLabel={GetOptionLabel}
        isOptionEqualToValue={IsOptionEqualToValue}
        options={filteredEntities}
        autoComplete
        fullWidth
        onOpen={onPopupOpen}
        onClose={onPopupClose}
        freeSolo
        filterOptions={(x) => x}
        includeInputInList
        value={option}
        disabled={disabled || creating}
        noOptionsText={t("common:noResults")}
        onChange={onOptionSelected}
        ListboxProps={{
          className: classes.listbox
        }}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            variant="filled"
            fullWidth
            sx={TEXTFIELD_SX}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
        renderOption={(props, option) => {
          return option.isAdd ? (
            <li {...props} key={option.id} onClick={onBeginCreate}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <PersonAddIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography>
                    {option.name}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          ) : (
            <li {...props} key={option.id}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <PersonIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Typography>
                    {option.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option?.email} - {option.location?.place || ""}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />

      {
        create ? (
          <EntityEditDialog
            createTitle={t("contactRegister:create")}
            fields={fields}
            entity={defaultValues}
            open
            onCancel={() => setCreate(false)}
            onCreate={onCreateEntity}
          />
        ) : null
      }
    </>
  );
}
