import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { UploadFileOutlined as UploadIcon } from "@mui/icons-material";
import EmptyState from "../../../common/core/EmptyState";
import DropTarget from "../../../common/core/DropTarget";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative"
    },
    emptyContainer: {
      border: `3px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      borderStyle: "dashed",
    },
    clickableFileUpload: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      opacity: 0,
      zIndex: 2
    },
  })
);

export default function DragAndDropSection(props) {
  const { onChange } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [dragOver, setDragOver] = useState(0);

  const onDragEnter = useCallback((ev) => {
    try {
      setDragOver(t => t + 1);
    } catch (err) {
      console.log(err)
      setDragOver(t => t + 1);
    }
  }, [setDragOver]);

  const onDragLeave = useCallback((ev) => {
    try {
      setDragOver(t => Math.max(t - 1, 0));
    } catch (err) {
      console.log(err)
      setDragOver(t => Math.max(t - 1, 0));
    }
  }, [setDragOver]);

  const onDragOver = useCallback((ev) => ev.preventDefault(), []);

  const onFileSelect = useCallback(async (ev) => {
    ev.preventDefault();
    setDragOver(0);

    const files = !!ev?.dataTransfer?.files ? [...ev.dataTransfer.files] : [ev.target?.files?.[0]];

    if (files.length !== 0 && !!files[0]) {
      const file = files[0];

      if (file.type.endsWith("csv")) {

        const reader = new FileReader();

        reader.onload = () => {
          const data = reader.result;

          if (!!data && typeof onChange === "function") {
            onChange(data);
          }
        };

        reader.readAsText(file);
      }
    }

  }, [setDragOver, onChange]);


  return (
    <Grid
      className={classes.root}
      item container
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onFileSelect}
      onDragOver={onDragOver}
    >

      <EmptyState
        className={classes.emptyContainer}
        title={t("importPage:dragAndDrop:title")}
        description={t("importPage:dragAndDrop:description")}
        icon={<UploadIcon />}
      >
        <input
          className={classes.clickableFileUpload}
          type="file"
          accept=".csv"
          onChange={onFileSelect}
        />

      </EmptyState>

      {
        dragOver > 0 ? (
          <DropTarget
            title={t("importPage:dragAndDrop:activeTitle")}
            description={t("importPage:dragAndDrop:description")}
          />
        ) : null
      }

    </Grid>
  );
}

