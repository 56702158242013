import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { useMission } from "../../../../providers/MissionProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { Add as AddIcon } from "@mui/icons-material";
import { Grid, Divider, ButtonBase, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { OrderEnumsLists } from "../../../../common/constants";
import { CalcToPaySum, GetOrderState, FixNumber } from "../../../../common/orders";
import { useStation } from "../../../../providers/StationProvider";
import EmptyState from "../../../common/core/EmptyState";
import config from "../../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    grid: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
    },
    button: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: "100%",
      borderRadius: theme.spacing(1)
    },
  })
);

export default function MissionTaskOrdersPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { orders, makeOrder } = useMission();
  const { priceUnit } = useStation();

  const isEmpty = orders.length === 0;

  return (
    <ResponsiveSubPage
      title={t("taskOrdersPage:title")}
      maxWidth={config.pageSize.small}
      backPath="../"
      backButton
      secondaryActionComponent="label"
      secondaryActionIcon={<AddIcon />}
      secondaryAction={t("taskOrdersPage:add")}
      onSecondaryAction={() => makeOrder()}

    >
      <ToggleSurface xs={12}>
        <Grid item container className={classes.grid}>
          {orders.map((order, index) => <OrderItem order={order} orders={orders} key={index} currency={priceUnit} />)}
        </Grid>

        {
          isEmpty ? (
            <EmptyState 
              title={t("taskOrdersPage:emptyStateTitle")}
              description={t("taskOrdersPage:emptyStateDescription")}
            />
          ) : null
        }

      </ToggleSurface>
    </ResponsiveSubPage>
  );
}

function OrderItem(props) {
  const { order, orders, currency } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const hasDeductible = !!order.deductible;
  const orderState = GetOrderState(order);

  return (
    <Grid item xs={12}>
      <NavLink to={`./${order.id}`}>
        <ButtonBase className={classes.button}>
          <Grid container>

            <Grid item container xs={12}>
              <Grid item xs>
                <Typography variant="subtitle1" textAlign="left">
                  {t("taskOrdersPage:order")} {order.name} - {order.customer?.name || ""}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="subtitle1">
                  {t(`order:enums:orderState:${OrderEnumsLists.OrderState[orderState]}`)}
                </Typography>
              </Grid>
            </Grid>


            <Grid item container xs={12}>
              <Grid item xs>
                <Typography variant="body2" color="textSecondary" textAlign="left">
                  {order.orderLines.length === 1 ? t("taskOrdersPage:product") : `${order.orderLines.length} ${t("taskOrdersPage:products")}`} {hasDeductible ? `${t("taskOrdersPage:and")} ${FixNumber(order.deductible.price.value)} ${t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[order.deductible.price.currency]}`)} ${t("taskOrdersPage:deductible")}` : ""}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {FixNumber(CalcToPaySum(order, orders))} {t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[currency]}`)}
                </Typography>
              </Grid>
            </Grid>


          </Grid>
        </ButtonBase>
        <Divider />
      </NavLink>
    </Grid>
  );
}
