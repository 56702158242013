import { makeStyles } from "tss-react/mui";
import { Divider, Grid, List, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { ToggleSurface } from "../../common/core/Surface";
import SettlementSummaryCard from "./common/SettlementSummaryCard";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { NavLink, useParams } from "react-router-dom";
import { useEntities, Compare, useEntityById } from "@emberly/zenith-client";
import { useMemo } from "react";
import SettlementInfoCard from "./common/SettlementInfoCard";
import config from "../../../config";

const useStyles = makeStyles()(
  (theme) => ({
    list: {
      width: "100%",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .MuiListItemText-primary": {
        fontWeight: 500
      }
    }
  })
);

export default function CashSettlementPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { settlementId } = useParams();

  const { entities: orders } = useEntities("Order", useMemo(() => ({ path: "payment.terminal.settlementId", comparer: Compare.EQ, value: settlementId }), [settlementId]), !!settlementId);
  const { entity: settlement } = useEntityById("CashSettlement", settlementId);

  return (
    <ResponsivePage
      title={t("cashSettlement:title")}
      maxWidth={config.pageSize.tiny}
      backButton="../"
    >

      <ToggleSurface>
        <Grid item xs={12} container spacing={2}>

          <Grid item xs={12}>
            <SettlementInfoCard settlement={settlement} loading={!settlement} />
          </Grid>

          <Grid item xs={12}>
            <SettlementSummaryCard settlement={settlement} loading={!settlement} />
          </Grid>

          <Grid item xs={12}>
            <List className={classes.list}>
              <SectionItem
                to="./orders"
                primary={t("cashSettlement:orders")}
                secondary={`${t("cashSettlement:ordersDescription")} ${orders.length} ${t("cashSettlement:order")}`}
              />
            </List>
          </Grid>

        </Grid>
      </ToggleSurface>

    </ResponsivePage>
  );
}


function SectionItem(props) {
  const { to, primary, secondary, disabled } = props;

  return (
    <NavLink to={disabled ? undefined : to}>
      <ListItemButton disabled={disabled}>
        <ListItemText
          primary={primary}
          secondary={secondary}
        />
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
      </ListItemButton>
      <Divider />
    </NavLink>
  );
}

