import { Grid, Typography } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import UserTable from "./common/UserTable";
import { useUserManagement } from "../../../providers/UserManagementProvider";
import InvitesTable from "./common/InvitesTable";
import { Add as AddIcon } from "@mui/icons-material"
import { useDevice } from "../../../providers/DeviceProvider";
import config from "../../../config";

export default function UserManagementPage() {
  const { t } = useTranslation();
  const { inviteUser, invites, users } = useUserManagement();
  const { slim } = useDevice();

  return (
    <ResponsivePage
      maxWidth={config.pageSize.compact}
      title={t("userManagement:header")}
      secondaryAction={t("userManagement:inviteUser")}
      secondaryActionIcon={<AddIcon />}
      onSecondaryAction={inviteUser}
    >

      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          {t("userManagement:description")}<br/>
          <a href="https://help.getassist.app/nb/articles/10104205-roller-og-tilgangsstyring" target="_blank" style={{ color: "inherit" }}>{t("userManagement:linkText")}</a>
        </Typography>
      </Grid>

      <ToggleSurface
        title={t("userManagement:invites:title")}
        collapse={invites.length === 0}
      >
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <InvitesTable slim={slim} />
          </Grid>
        </Grid>
      </ToggleSurface>

      <ToggleSurface
        title={t("userManagement:users:title")}
        collapse={users.length === 0}
      >
        <Grid item xs={12}>
          <UserTable slim={slim} />
        </Grid>
      </ToggleSurface>

    </ResponsivePage>
  );
}
