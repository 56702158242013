import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../../providers/MissionProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useEntityField, Defaults, useAuth } from "@emberly/zenith-client";
import { ActivityEnums, MissionEnums, MissionEnumsLists } from "../../../../common/constants";
import { SetAllTasksCreated } from "../../../../common/mission";
import MissionActivityLog from "./MissionActivityLog";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { Skeleton } from "@mui/material";
import CompleteMissionDialog from "./CompleteMissionDialog";
import { useStation } from "../../../../providers/StationProvider";
import { TimeView } from "../../../common/core/Time";
import config from "../../../../config";

const { State } = MissionEnums;

export default function MissionLayout(props) {
  const { t } = useTranslation();
  const { mission, deleteMission, hasUpdate, updateMissionField, logMissionEvent } = useMission(); // TODO
  const navigate = useNavigate();
  const { taskNumber, missionId } = useParams();
  const { user } = useAuth();
  const { permissions } = useStation();

  const missionState = useEntityField("state", Defaults.Enum);
  const state = missionState.value;
  const setState = missionState.onChange;
  const backPath = !!taskNumber ? "../../" : "../";

  const [completeDialog, setCompleteDialog] = useState(false);

  const onPrimaryButton = useCallback(async () => {
    switch (state) {

      case State.Draft:
        await deleteMission();
        logMissionEvent(ActivityEnums.Type.MissionDeleted);
        navigate(backPath, { relative: "path" });
        break;

      case State.Created:
        setState(null, State.Cancelled);
        logMissionEvent(ActivityEnums.Type.MissionCancelled);
        break;

      default:
        console.log("unknown state");
    }

  }, [state, backPath, deleteMission, setState, logMissionEvent, navigate]);

  const onSecondaryButton = useCallback(async () => {
    switch (state) {

      case State.Draft:
      case State.Cancelled:
        navigate(`/activemissions/${missionId}`, { relative: "path" });
      case State.Completed:
        setState(null, State.Created);
        SetAllTasksCreated(user, mission, updateMissionField);
        logMissionEvent(ActivityEnums.Type.MissionCreated);
        break;

      case State.Created:
        setCompleteDialog(true);
        break;

      default:
        console.log("unknown state");
    }

  }, [state, setState, missionId, updateMissionField, user, mission, logMissionEvent, navigate]);


  const onCancelComplete = useCallback(() => setCompleteDialog(false), []);

  const onComplete = useCallback(() => {
    setState(null, State.Completed);
    logMissionEvent(ActivityEnums.Type.MissionCompleted);
    setCompleteDialog(false);
  }, [setState, logMissionEvent]);

  return (
    <ResponsiveSubPage
      title={`${t("mission:mission")} ${mission?.number} ${hasUpdate ? "*" : ""}`}
      caption={<TimeView time={mission?.created} />}
      stateTag={state !== 0 ? t(getStateText(state)) : <Skeleton variant="rounded" width="37px" />}
      stateTagColor={state !== 0 ? getStateColor(state) : undefined}
      primaryAction={state === State.Draft ? t("mission:deleteDraft") : state === State.Created ? t("mission:cancelMission") : undefined}
      onPrimaryAction={(state === State.Draft || state === State.Created) ? onPrimaryButton : undefined}
      secondaryAction={state === State.Draft ? t("mission:createMission") : state === State.Created ? (permissions.canCompleteMission ? t("mission:completeMission") : null) : t("mission:reactivateMission")}
      onSecondaryAction={onSecondaryButton}
      backPath={backPath}
      widget={<MissionActivityLog />}
      backButton
      maxWidth={config.pageSize.huge}
    >
      {props.children}
      {completeDialog ? <CompleteMissionDialog onClose={onCancelComplete} onComplete={onComplete} /> : null}
    </ResponsiveSubPage>
  );
}

function getStateText(state) {
  return `mission:enums:state:${MissionEnumsLists.State[state] || 0}`;
}

function getStateColor(state) {
  switch (state) {
    case State.Draft: return "warning";
    case State.Cancelled: return "error";
    case State.Created: return "info";
    case State.Completed: return "success";
    default: return "default";
  }
}