import React, { useState, createContext, useContext, useEffect, useMemo, useCallback } from "react";
import { ActivityEnums, OrderEnums, OrderEnumsLists, Roles, ROLES_CLAIM } from "../common/constants";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { GetUserReference } from "../common/orders";
import { useNavigate } from "react-router-dom";
import { MakeActivity } from "../common/activity";
import { useEntityFunctions, useAuth, useDataFetcher, useGET, useSignal, useEntities } from "@emberly/zenith-client";
import config from "../config";
import UpdateRequiredPage from "../components/pages/error/UpdateRequiredPage";

export const StationContext = createContext();
export const useStation = () => useContext(StationContext);

export default function StationContextProvider(props) {
  const [loading, setLoading] = useState(true);
  const { connection, connecting } = useSignal();
  const [updating, setUpdating] = useState(false);
  const [activeUsers, setActiveUsers] = useState(new Set());
  const [updateRequired, setUpdateRequired] = useState(false);
  const [station, setStation] = useState(null);
  const fetcher = useDataFetcher();
  const { getAccessTokenSilently, user } = useAuth();
  const [showActivityLog, setShowActivityLog] = useState(false);
  const { createEntity: createActivityLogEntity } = useEntityFunctions("ActivityLog", "cli");
  const { entities: warehouses } = useEntities("Warehouse");

  const { createEntity: createMissionEntity } = useEntityFunctions("Mission"); // pass missions down to table
  const navigate = useNavigate();

  useEffect(() => {
    if (!connecting && !!connection) {

      const onUserConnected = (orgId, userId) => {
        setActiveUsers(users => new Set([...users, userId]));
        connection.invoke("Heartbeat");
      };

      const onUserDisconnected = (orgId, userId) => {
        setActiveUsers(users => new Set([...users].filter(t => t !== userId)));
      };

      const onHeartbeat = (orgId, userId) => {
        setActiveUsers(users => new Set([...users, userId]));
      };

      connection.on("OnUserConnected", onUserConnected);
      connection.on("OnUserDisconnected", onUserDisconnected);
      connection.on("OnHeartbeat", onHeartbeat);

      let current = true;

      const cancelFetching = fetcher(`station`, (data) => {

        if (data?.minimumApiVersion > config.apiVersion) {
          setUpdateRequired(true);
        }

        if (current) {
          setStation(data);
          setLoading(false);
        }
      });

      return () => {
        current = false;
        cancelFetching();
        setLoading(true);
        connection.off("OnUserConnected", onUserConnected);
        connection.off("OnUserDisconnected", onUserDisconnected);
        connection.off("OnHeartbeat", onHeartbeat);
      };
    }
  }, [connection, connecting, fetcher]);

  const updateStation = useCallback(async (request) => {
    setUpdating(true);

    const res = await axios.patch(
      "/api/station",
      request,
      {
        headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
      }
    );

    setStation(s => ({ ...s, ...res.data }));
    setUpdating(false);
  }, [getAccessTokenSilently]);

  const logEvent = useCallback(async (data) => {
    try {
      await createActivityLogEntity({
        sender: GetUserReference(user),
        avatar: user.picture,
        link: window?.location?.pathname,
        ...data
      });
    } catch (err) {
      console.log("log error", err);
    }
  }, [createActivityLogEntity, user]);

  const createMissionDraft = useCallback(async () => {
    const mission = await createMissionEntity();
    logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.MissionDraftCreated, mission?.id));
    navigate(`/drafts/${mission.id}`, { relative: "path" });
  }, [createMissionEntity, navigate, logEvent]);


  const { isDriver, isAccountant, isOperator, isStationAdmin, isGlobalAdmin, permissions, roles } = useMemo(() => {
    const roles = new Set(user[ROLES_CLAIM] || []);
    const isDriver = roles.has(Roles.Driver);
    const isAccountant = roles.has(Roles.Accountant);
    const isStationAdmin = roles.has(Roles.StationAdmin);
    const isGlobalAdmin = roles.has(Roles.GlobalAdmin);
    const isOperator = roles.has(Roles.Operator);

    return {
      roles,
      isDriver,
      isOperator,
      isAccountant,
      isStationAdmin,
      isGlobalAdmin,
      permissions: {
        canCompleteMission: isStationAdmin || isAccountant
      },
    };
  }, [user]);


  return (
    <StationContext.Provider
      value={{
        loading: loading,
        activeUsers, // active users will return your own userId if there is any other instances of your user currently online.
        roles,
        user,
        station,
        updating,
        isDriver,
        isAccountant,
        isStationAdmin,
        isGlobalAdmin,
        isOperator,
        warehouses,
        permissions,
        updateStation,
        priceUnit: OrderEnums.Currency.NOK,
        priceUnitShorthand: "kr",
        orgId: user?.org_id,
        createMissionDraft,
        showActivityLog,
        setShowActivityLog,
        logEvent
      }}>
      {updateRequired ? <UpdateRequiredPage /> : props.children}
    </StationContext.Provider>
  );
}

export const useRoles = () => {
  const { data } = useGET("client/roles/list");

  return useMemo(() => {
    const m = new Map();
    data?.roles?.forEach(t => m.set(t.id, t));
    return m;
  }, [data]);
};


export const useStationCurrency = () => {
  const { t } = useTranslation();
  const { priceUnit } = useStation();
  return t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`);
};

export const getCurrencyShorthand = (t, priceUnit) => {
  return !priceUnit ? "" : t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit === undefined ? 0 : priceUnit]}`);
};

export const getCurrency = (t, priceUnit) => {
  return t(`order:enums:currency:${OrderEnumsLists.Currency[priceUnit === undefined ? 0 : priceUnit]}`);
};

