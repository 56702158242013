import { makeId } from "@emberly/zenith-client";
import axios from "axios";
import { ActivityEnums, MapIcons, MissionEnums, TaskEnums, TaskEnumsLists } from "./constants";
import moment from "moment/moment";
import { FixNumber, sanitizeNumber } from "./orders";
import Decimal from "decimal.js";
import { HaversineDistance } from "./maphelpers";
import { MakeActivity } from "./activity";
import ExifReader from "exifreader";
import config from "../config";


export function MapMissionTasks(mission) {
  return mission.salvageTasks.map((t, i) => ({
    ...t,
    id: `${mission.id}/${t.number}`,
    missionId: mission.id,
    missionNumber: mission.number,
    missionTarget: mission.target,
    missionActors: mission.actors,
    missionDetails: mission.details,
    missionState: mission.state,
    taskIndex: i,
    state: AssumeTaskStateEnum(t),
    stateData: AssumeTaskState(t)
  }));
}


export function AllTasksCompleted(mission) {
  if (!mission) return true;

  const tasks = mission.salvageTasks;

  for (let i = 0; i < tasks.length; i++) {
    const state = AssumeTaskStateEnum(tasks[i]);
    if (state < TaskEnums.State.Completed) {
      return false;
    }
  }

  return true;
}

export function SetAllTasksCreated(user, mission, updateMissionField) {
  const time = moment.utc().toISOString();

  mission?.salvageTasks?.forEach((task, index) => {
    if (!task.execution.history.created) {
      updateMissionField(
        `salvageTasks.${index}.execution.history.created`,
        {
          created: time,
          lastModified: time,
          lastChangedBy: { id: user.sub, name: user.name }
        }
      );
    }
  });
}

export function MakeStateChange(user, geolocation = null) {

  const time = moment.utc().toISOString();

  return {
    created: time,
    lastModified: time,
    lastChangedBy: { id: user.sub, name: user.name },
    geolocation,
  };
}


export async function UpdateAssignedState(missionTask, assignedState, updateEntity, user, logEvent) {
  let salvageTasks = [];

  salvageTasks[missionTask.taskIndex] = {
    execution: {
      driverAssignedState: assignedState,
      history: {
        responded: MakeStateChange(user)
      }
    }
  };

  await updateEntity({ id: missionTask.missionId, salvageTasks }, [`salvageTasks.${missionTask.taskIndex}.execution.driverAssignedState`, `salvageTasks.${missionTask.taskIndex}.execution.history.responded`]);

  switch (assignedState) {
    case TaskEnums.DriverAssignedState.Accepted:
      logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.AcceptedTask, missionTask.missionId, "", "", { id: missionTask.taskId, name: missionTask.taskName }));
      break;

    case TaskEnums.DriverAssignedState.Rejected:
      logEvent(MakeActivity(ActivityEnums.Category.Mission, ActivityEnums.Type.RejectedTask, missionTask.missionId, "", "", { id: missionTask.taskId, name: missionTask.taskName }));
      break;

    default:
      console.log("invalid assigned state");
      break;
  }
}


export function AssumeStorageState(mission) {
  if (!mission || !mission.storageTask) return MissionEnums.StorageState.None;
  if (!mission.storageTask.delivered) return MissionEnums.StorageState.Stored;
  return MissionEnums.StorageState.Delivered;
}

export function GetDaysStored(storageTask) {
  const storedTime = storageTask.stored;
  const deliveryTime = storageTask.deliveryTime;
  const days = !!storedTime ? moment(!!deliveryTime ? deliveryTime : undefined).add(1, "days").startOf("day").diff(moment(storedTime).startOf("day"), "days") : 0;
  return days <= 0 ? 0 : Math.max(0, days - 1);
}

export function GetStoragePrice(storageTask) {
  const daysElapsed = GetDaysStored(storageTask);
  return daysElapsed > 0 ? FixNumber(new Decimal(sanitizeNumber(storageTask.rate.value)).mul(daysElapsed)) : 0
}

export function AssumeTaskStateEnum(task) {
  const history = task?.execution?.history;

  if (!history) return TaskEnums.State.None;

  if (!!history.acknowledged) {
    return TaskEnums.State.Acknowledged;
  } else if (!!history.completed) {
    return TaskEnums.State.Completed;
  } else if (!!history.loaded) {
    return TaskEnums.State.Loaded;
  } else if (!!history.arrived) {
    return TaskEnums.State.Arrived;
  } else if (!!history.started) {
    return TaskEnums.State.Started;
  } else if (!!history.responded) {
    return TaskEnums.State.Responded;
  } else if (!!history.assigned) {
    return TaskEnums.State.Assigned;
  } else if (!!history.created) {
    return TaskEnums.State.Created;
  }

  return TaskEnums.State.None;
}

export function AssumeTaskState(task) {
  const history = task?.execution?.history;

  if (!history) return null;

  if (!!history.acknowledged) {
    return history.acknowledged;
  } else if (!!history.completed) {
    return history.completed;
  } else if (!!history.loaded) {
    return history.loaded;
  } else if (!!history.arrived) {
    return history.arrived;
  } else if (!!history.started) {
    return history.started;
  } else if (!!history.responded) {
    return history.responded;
  } else if (!!history.assigned) {
    return history.assigned;
  } else if (!!history.created) {
    return history.created;
  }

  return null;
}


export function GetTaskDriverState(driverAssignedState, state, t, shorthand = false) {

  switch (state) {

    case TaskEnums.State.Created:
    case TaskEnums.State.None:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Created`);

    case TaskEnums.State.Assigned:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Assigned`);

    case TaskEnums.State.Responded:
      return t(`task:enums:driverAssignedState:${TaskEnumsLists.DriverAssignedState[driverAssignedState]}`)

    case TaskEnums.State.Started:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Started`);

    case TaskEnums.State.Arrived:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Arrived`);

    case TaskEnums.State.Loaded:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Loaded`);

    case TaskEnums.State.Completed:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Completed`);

    case TaskEnums.State.Acknowledged:
      return t(`task:enums:${shorthand ? "stateShorthand" : "state"}:Acknowledged`);

    default:
      return "";
  }
}

export function GetTaskStateBackgroundColor(state, driverAssignedState = 0) {

  switch (state) {

    case TaskEnums.State.Created:
    case TaskEnums.State.None:
      return "#FFA357";

    case TaskEnums.State.Assigned:
      return "#FFF4E5";

    case TaskEnums.State.Responded:
      return driverAssignedState === TaskEnums.DriverAssignedState.Accepted ? "#D3E5EF" : "#D32F2F";

    case TaskEnums.State.Started:
      return "#EEE0DA";

    case TaskEnums.State.Arrived:
      return "#F5E0E9";

    case TaskEnums.State.Loaded:
      return "#E8DEEE";

    case TaskEnums.State.Completed:
      return "#EDF7ED";

    case TaskEnums.State.Acknowledged:
      return "#EDF7ED";

    default:
      return "#EF6C00";
  }
}

export function GetTaskStateTextColor(state, driverAssignedState = 0) {

  switch (state) {

    case TaskEnums.State.Responded:
      return driverAssignedState === TaskEnums.DriverAssignedState.Accepted ? "rgba(0,0,0,0.72)" : "#FFF";

    case TaskEnums.State.Created:
    case TaskEnums.State.None:
      return "#FFF";

    case TaskEnums.State.Assigned:
    case TaskEnums.State.Started:
    case TaskEnums.State.Arrived:
    case TaskEnums.State.Loaded:
    case TaskEnums.State.Completed:
    case TaskEnums.State.Acknowledged:
      return "rgba(0,0,0,0.72)";

    default:
      return "rgba(0,0,0,0.72)";
  }
}


export function GetTaskDriverStateIcon(driverAssignedState, state) {
  switch (state) {
    case TaskEnums.State.None:
    case TaskEnums.State.Created:
    case TaskEnums.State.Responded:
    case TaskEnums.State.Assigned:
    case TaskEnums.State.Started:
      return driverAssignedState === TaskEnums.DriverAssignedState.Accepted ? MapIcons.Pins.Moving : MapIcons.Pins.Issue;

    case TaskEnums.State.Arrived:
    case TaskEnums.State.Loaded:
      return MapIcons.Pins.Person;

    case TaskEnums.State.Completed:
    case TaskEnums.State.Acknowledged:
      return MapIcons.Pins.Resolved;

    default:
      return MapIcons.Pins.Issue;
  }
}


export function MakeEmptyTask(num = 1, name = null) {
  return {
    taskId: makeId(),
    taskName: name,
    taskType: 1,
    number: num,
    route: {
      waypoints: []
    },
    execution: {
      history: {},
      driver: { id: null, name: null },
      vehicle: { id: null, name: null },
      driverAssignedState: 0
    },
    state: 0,
    resolvedAtLocation: false,
    description: "",
    comment: "",
    keyPlacement: ""
  };
}

export function MakeEmptyStorage(num = 1) {
  return {
    taskId: makeId(),
    taskName: null,
    taskType: 2,
    number: num,
    comment: "",
    keyNumber: "",
    protected: false,
    delivered: false,
    rate: { value: "", currency: 1 },
    warehouse: {
      id: null,
      name: null,
      location: null
    }
  };
}

export function isImage(ext) {
  return ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "webp" || ext === "gif";
}

export function MakeFile(f) {
  const extension = f.name.substr(f.name.lastIndexOf(".") + 1).toLowerCase().trim();
  const fileIsImage = isImage(extension);


  return {
    id: makeId(),
    complete: false,
    name: f.name,
    size: f.size,
    mime: f.type,
    type: fileIsImage ? 1 : 0,
    extension
  };
}

export async function ExtractImageMetadata(file) {
  try {
    const tags = await ExifReader.load(file);

    if (!tags) return null;

    const latitude = tags?.GPSLatitude?.description;
    const longitude = tags?.GPSLongitude?.description;
    const timestamp = tags?.DateTime?.description;
    const latitudeRef = tags?.GPSLatitudeRef?.value?.[0] || "";
    const longitudeRef = tags?.GPSLongitudeRef?.value?.[0] || "";


    if (typeof latitude !== "number" || typeof longitude !== "number") return null;

    const metadata = {
      coordinates: [longitude, latitude],
      coordinatesRef: [longitudeRef, latitudeRef],
      timestamp
    };

    return metadata;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function GetLightboxSlide(image) {
  const metadata = image.metadata;
  let overlay = "";

  if (!!metadata) {
    const lon = new Decimal(metadata.coordinates[0]).toFixed(4);
    const lat = new Decimal(metadata.coordinates[1]).toFixed(4);
    const lonRef = metadata?.coordinatesRef?.[0] || "";
    const latRef = metadata?.coordinatesRef?.[1] || "";

    const annotation = encodeURIComponent(btoa(`GPS: ${lat}${latRef}, ${lon}${lonRef}\nTime: ${metadata.timestamp || ""}`));

    overlay = `,l-text,ie-${annotation},co-white,fs-30,bg-00000060,lfo-bottom_left,pa-10,ia-left,l-end`;
  }

  const url = `${image.url.replace(`https://storage.googleapis.com/assist-private-${config.isProd ? "prod" : "dev"}`, "https://ik.imagekit.io/assist/")}?tr=h-1080${overlay}`
  // TODO include original url for download original

  return {
    src: url, title: image.name,
    share: { url, title: image.name },
  };
}



export async function UploadMissionFile(file, policy, onUploadProgress) {
  try {
    const form = new FormData();

    Object.keys(policy.postPolicy.fields).forEach(key => {
      if (key !== "postUrl") {
        form.append(key, policy.postPolicy.fields[key]);
      }
    });

    form.append("file", file);

    await axios.post(
      policy.postPolicy["postUrl"],
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      }
    );

  } catch (err) {
    console.log("error uploading resource file to handle");
    console.log(err);
  }

}


export function GetTargetDetails(mission) {
  const targetType = mission.target.type;
  return targetType === 1 ? mission.target.carDetails : (targetType === 2 ? mission.target.boatDetails : null);
}



export function ShouldAskForUpdatedPosition(geolocation, waypoint = null) {
  if (!geolocation || !geolocation.coords || !geolocation.coords.longitude || !geolocation.coords.latitude) return false;
  if (!waypoint) return true;

  const wCoordinates = waypoint.coordinates;

  if (!wCoordinates || wCoordinates?.length < 2) return true;

  const dtSeconds = (Date.now() - geolocation.timestamp) / 1000;

  if (dtSeconds > 65) {
    return false;
  }

  const wLongitude = wCoordinates[0];
  const wLatitude = wCoordinates[1];

  const gLongitude = geolocation.coords.longitude;
  const gLatitude = geolocation.coords.latitude;
  const gAcc = geolocation.coords.accuracy || 0;

  const distance = Math.max(0, HaversineDistance(wLongitude, wLatitude, gLongitude, gLatitude) - gAcc);

  return distance > 150; // IF distance larger than 100 meters, correct position
}

